import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTools, FaServer, FaEuroSign } from 'react-icons/fa';

const OffreSiteModele = () => {
  return (
    <div className="bg-white py-20 md:py-28">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] text-white py-20 px-4">
        <div className="container mx-auto max-w-4xl text-center">
          <h1 className="text-4xl sm:text-5xl font-bold mb-4">
            Votre site web professionnel pour seulement{' '}
            <span className="text-yellow-300">9,99€/mois</span>
          </h1>
          <p className="text-lg sm:text-xl mb-6">
            Une solution clé en main pour établir votre présence en ligne avec
            un site prêt à l’emploi. Maintenance incluse, mises à jour faciles
            et sans tracas !
          </p>
          <Link
            to="/inscription"
            className="bg-yellow-300 text-gray-900 px-6 py-3 rounded-lg text-lg font-medium transition-all hover:bg-yellow-400"
          >
            S'abonner maintenant
          </Link>
        </div>
      </div>

      {/* Pourquoi choisir notre offre ? */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">
          Pourquoi choisir notre offre ?
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="flex items-start gap-4">
            <FaCheckCircle className="text-green-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Maintenance incluse
              </h3>
              <p className="text-gray-600">
                Nous nous occupons des mises à jour régulières, corrections de
                bugs et optimisations pour que votre site reste performant.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <FaTools className="text-blue-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Mises à jour faciles
              </h3>
              <p className="text-gray-600">
                Nous mettons à jour vos textes et images pour vous, sans frais
                supplémentaires.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <FaServer className="text-purple-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Hébergement performant
              </h3>
              <p className="text-gray-600">
                Hébergement sécurisé et rapide inclus, pour garantir une
                expérience utilisateur optimale.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <FaEuroSign className="text-yellow-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Domaine personnalisé
              </h3>
              <p className="text-gray-600">
                Utilisez votre propre nom de domaine pour seulement{' '}
                <strong>20€/an</strong> auprès de notre fournisseur partenaire.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <FaCheckCircle className="text-green-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Fonctionnalités supplémentaires
              </h3>
              <p className="text-gray-600">
                Ajoutez des fonctionnalités sur mesure (comme un formulaire de
                contact ou une galerie photo) avec un paiement ponctuel, selon
                nos tarifs.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <FaTools className="text-blue-500 w-8 h-8" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Support client réactif
              </h3>
              <p className="text-gray-600">
                Bénéficiez d’un accompagnement personnalisé pour toutes vos
                questions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tarifs et détails */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Nos Tarifs</h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-8">
            Découvrez notre solution flexible et abordable pour gérer votre
            présence en ligne sans tracas. Ajoutez des fonctionnalités à la
            demande avec nos options supplémentaires.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Abonnement mensuel
              </h3>
              <p className="text-gray-600 text-lg mb-4">9,99€/mois</p>
              <p className="text-gray-600 mb-4">
                Inclut hébergement, maintenance, et mises à jour.
              </p>
              <Link
                to="/inscription"
                className="bg-purple-600 text-white px-4 py-2 rounded-lg transition hover:bg-purple-700"
              >
                S'abonner
              </Link>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Domaine personnalisé
              </h3>
              <p className="text-gray-600 text-lg mb-4">20€/an</p>
              <p className="text-gray-600 mb-4">
                Configurez un domaine unique pour votre site.
              </p>
              <button className="bg-purple-600 text-white px-4 py-2 rounded-lg transition hover:bg-purple-700">
                Ajouter
              </button>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Options supplémentaires
              </h3>
              <p className="text-gray-600 mb-4">
                Personnalisez votre site avec nos services :
              </p>
              <ul className="text-left text-gray-600 space-y-2 mb-6">
                <li>- Page supplémentaire : 100€</li>
                <li>- Blog : 500€</li>
                <li>- Intégration Stripe ou PayPal : 500€</li>
                <li>- Maintenance avancée : 100€/mois</li>
              </ul>
              <Link
                to="/tarifs"
                className="bg-purple-600 text-white px-4 py-2 rounded-lg transition hover:bg-purple-700"
              >
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Appel à l'action */}
      <div className="bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] text-white py-16 text-center">
        <h2 className="text-3xl font-bold mb-4">Prêt à démarrer ?</h2>
        <p className="text-lg mb-6">
          Lancez votre site web professionnel dès aujourd'hui avec notre offre
          clé en main.
        </p>
        <Link
          to="/inscription"
          className="bg-yellow-300 text-gray-900 px-6 py-3 rounded-lg text-lg font-medium transition hover:bg-yellow-400"
        >
          Rejoignez-nous maintenant
        </Link>
      </div>
    </div>
  );
};

export default OffreSiteModele;
