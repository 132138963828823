import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { HiOutlineArrowLeft } from 'react-icons/hi';

const CancelPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50">
      <FaTimesCircle className="text-red-600 text-6xl mb-6 animate-pulse" />
      <h1 className="text-3xl font-bold text-gray-800">Paiement annulé</h1>
      <p className="text-gray-600 mt-4 text-center">
        Votre paiement a été annulé. Si cela est une erreur, vous pouvez réessayer. <br />
        Sinon, retournez à l’accueil pour explorer d'autres options.
      </p>
      <div className="mt-8 flex space-x-4">
        <button
          onClick={() => (window.location.href = '/')}
          className="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-900 transition"
        >
          <HiOutlineArrowLeft className="inline-block mr-2" />
          Retour à l'accueil
        </button>
        <button
          onClick={() => (window.location.href = '/nos-offres')}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
        >
          Voir nos offres
        </button>
      </div>
    </div>
  );
};

export default CancelPage;
