import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, RefreshCw } from "lucide-react";

const LoadingScreen = () => (
  <div className="fixed inset-0 bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] flex items-center justify-center">
    <div className="flex flex-col items-center gap-4">
      <RefreshCw className="w-12 h-12 text-white animate-spin" />
      <p className="text-white text-lg font-medium">Redirection...</p>
    </div>
  </div>
);

const EmailConfirmationSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          setIsRedirecting(true);
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);

    const timer = setTimeout(() => {
      setIsRedirecting(true);
      setTimeout(() => {
        navigate('/login');
      }, 500);
    }, 4500);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [navigate]);

  if (isRedirecting) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
        <div className="flex flex-col items-center space-y-6">
          <CheckCircle size={64} className="text-green-500 animate-bounce" />
          
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Email confirmé avec succès !
          </h1>

          <div className="h-2 w-full bg-gray-200 rounded-full">
            <div 
              className="h-2 bg-green-500 rounded-full transition-all duration-1000"
              style={{ width: `${(countdown / 5) * 100}%` }}
            />
          </div>

          <p className="text-gray-600 text-center">
            Vous serez redirigé vers la page de connexion dans {countdown} seconde{countdown !== 1 && 's'}.
          </p>

          <button
            onClick={() => {
              setIsRedirecting(true);
              setTimeout(() => navigate('/login'), 300);
            }}
            className="px-6 py-2 bg-gradient-to-r from-purple-800 to-indigo-600 text-white rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg"
          >
            Aller à la connexion
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmationSuccess;