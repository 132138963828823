import React, { useState } from 'react';
import { Mail, MapPin, Clock, Smartphone, ArrowLeft } from 'lucide-react';
import Particles from '../animations/Particles';
import { useNavigate } from 'react-router-dom';

const NousContacter = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('Envoi en cours...');

    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        'access_key',
        process.env.REACT_APP_WEB3FORM_API_KEY
      );
      formDataToSend.append('prenom', formData.prenom);
      formDataToSend.append('nom', formData.nom);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('message', formData.message);

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataToSend,
      });

      const data = await response.json();
      console.log('Réponse Web3Forms :', data);

      if (data.success) {
        setMessage('Message envoyé avec succès !');
        setFormData({ prenom: '', nom: '', email: '', message: '' });
      } else {
        setMessage(data.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setMessage('Erreur de connexion. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalendly = () => {
    window.open('https://calendly.com/vincentvaiti/30min', '_blank');
  };

  return (
    <div className="relative min-h-screen w-full py-20">
      {/* Titre principal */}
      <div className="text-center pt-6 sm:pt-10 md:pt-20">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6">
          Nous Contacter
        </h1>
        <p className="text-sm sm:text-base text-gray-500 max-w-md sm:max-w-xl mx-auto">
          Une question ? Besoin d'information ? Notre équipe est à votre
          disposition pour répondre à vos besoins !
        </p>
      </div>

      {/* Bouton retour */}
      <div className="container mx-auto px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-sm sm:text-base text-gray-600 hover:text-gray-900 transition-colors mt-6 sm:mt-8 mb-4 group"
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour à l'accueil</span>
        </button>
      </div>

      {/* Contenu principal */}
      <div className="flex flex-1 flex-col md:flex-row w-[90vw] max-w-6xl mx-auto gap-6 sm:gap-8">
        {/* Section Contact */}
        <div className="flex flex-col gap-6 sm:gap-8 relative">
          {/* Section 1 */}
          <div className="relative p-6 flex flex-col items-center bg-black text-white rounded-lg shadow-lg overflow-hidden">
            {/* Particles pour Section 1 */}
            <Particles
              className="absolute inset-0 z-0"
              quantity={5}
              staticity={40}
              ease={50}
            />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-4">
                <MapPin className="sm:size-16 md:size-24 lg:size-32" />
                <h3 className="text-lg sm:text-xl font-bold">Localisation</h3>
              </div>
              <p className="text-sm sm:text-base text-center">
                Paris & Périphérie, France
              </p>
            </div>
          </div>

          {/* Section 2 */}
          <div className="relative p-6 flex flex-col items-center bg-black text-white rounded-lg shadow-lg overflow-hidden">
            {/* Particles pour Section 2 */}
            <Particles
              className="absolute inset-0 z-0"
              quantity={5}
              staticity={40}
              ease={50}
            />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-4">
                <Smartphone className="sm:size-16 md:size-24 lg:size-32" />
                <h3 className="text-lg sm:text-xl font-bold">Téléphone</h3>
              </div>
              <p className="text-sm sm:text-base text-center">07 67 60 34 49</p>
            </div>
          </div>

          {/* Section 3 */}
          <div className="relative p-6 flex flex-col items-center bg-black text-white rounded-lg shadow-lg overflow-hidden">
            {/* Particles pour Section 3 */}
            <Particles
              className="absolute inset-0 z-0"
              quantity={5}
              staticity={40}
              ease={50}
            />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-4">
                <Mail className="sm:size-16 md:size-24 lg:size-32" />
                <h3 className="text-lg sm:text-xl font-bold">E-mail</h3>
              </div>
              <p className="text-sm sm:text-base text-center">
                contact@vgomcreation.fr
              </p>
            </div>
          </div>

          {/* Section 4 */}
          <div className="relative p-6 flex flex-col items-center bg-black text-white rounded-lg shadow-lg overflow-hidden">
            {/* Particles pour Section 4 */}
            <Particles
              className="absolute inset-0 z-0"
              quantity={6}
              staticity={40}
              ease={50}
            />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-4">
                <Clock className="sm:size-16 md:size-24 lg:size-32" />
                <h3 className="text-lg sm:text-xl font-bold">Horaires</h3>
              </div>
              <p className="text-sm sm:text-base text-center">
                Du lundi au vendredi : 9 h - 17 h
              </p>
              <p className="text-sm sm:text-base text-center">
                Week-end & jours fériés : Fermé
              </p>
            </div>
          </div>
        </div>

        {/* Section Formulaire */}
        <div className="flex-1 p-5 sm:p-7 lg:p-10 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6">
            Formulaire de Contact
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="prenom"
                  className="block text-sm sm:text-base text-gray-600 mb-2 font-medium"
                >
                  Prénom *
                </label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  value={formData.prenom}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-google"
                />
              </div>
              <div>
                <label
                  htmlFor="nom"
                  className="block text-sm sm:text-base text-gray-600 mb-2 font-medium"
                >
                  Nom *
                </label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-google"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm sm:text-base text-gray-600 mb-2 font-medium"
              >
                E-mail *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-google"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm sm:text-base text-gray-600 mb-2 font-medium"
              >
                Message *
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-google"
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-black text-white py-3 rounded-lg font-medium transition-all duration-300 hover:bg-hover-google hover:shadow-lg"
            >
              {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
            </button>
            {message && (
              <p
                className={`text-center mt-4 font-medium ${
                  message.includes('succès') ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {message}
              </p>
            )}
            <button
              type="button"
              onClick={handleCalendly}
              className="w-full mt-4 bg-black text-white py-3 rounded-lg font-medium transition-all duration-300 hover:bg-hover-google hover:shadow-lg"
            >
              Planifier un rendez-vous
            </button>
            <p className="text-sm sm:text-base text-gray-500 text-left mt-6">
              Les champs marqués d'un astérisque (*) sont obligatoires.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NousContacter;
