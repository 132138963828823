import React, { useEffect, useState } from 'react';

const Particles = ({ className = "", quantity = 30, staticity = 50, ease = 50 }) => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    // Création des particules avec des paramètres ajustés pour le nouveau design
    const initialParticles = Array.from({ length: quantity }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 2 + 1, // Particules plus petites (1-3px)
      velocityX: (Math.random() - 0.5) * 0.2, // Mouvement plus lent et fluide
      velocityY: (Math.random() - 0.5) * 0.2,
      opacity: Math.random() * 0.3 + 0.1 // Opacité variable entre 0.1 et 0.4
    }));

    setParticles(initialParticles);

    // Animation des particules
    const animateParticles = () => {
      setParticles(prevParticles =>
        prevParticles.map(particle => {
          let newX = particle.x + particle.velocityX;
          let newY = particle.y + particle.velocityY;

          // Marge de sécurité plus grande pour éviter les bords
          if (newX < 10 || newX > 90) particle.velocityX *= -1;
          if (newY < 10 || newY > 90) particle.velocityY *= -1;

          // Limites ajustées
          newX = Math.max(10, Math.min(90, newX));
          newY = Math.max(10, Math.min(90, newY));

          return {
            ...particle,
            x: newX,
            y: newY
          };
        })
      );
    };

    const animationFrame = setInterval(animateParticles, 60); // Animation légèrement plus lente

    return () => clearInterval(animationFrame);
  }, [quantity]);

  return (
    <div className={`absolute inset-0 overflow-hidden ${className}`}>
      {particles.map((particle, index) => (
        <div
          key={index}
          className="absolute rounded-full bg-violetta transition-all duration-300"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: `${particle.size * 3}px`, // Taille légèrement réduite
            height: `${particle.size * 3}px`,
            opacity: particle.opacity,
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 4px rgba(33, 150, 243, 0.3)' // Légère lueur
          }}
        />
      ))}
    </div>
  );
};

export default Particles;