import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordEmail } from "../api";
import { CheckCircle } from "lucide-react";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await sendForgotPasswordEmail(email);
      setSuccess(true);
    } catch (err) {
      setError(
        err.response?.data?.error?.message || "Une erreur s'est produite."
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
      <div className="w-full max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold text-center mb-4 text-gray-800">
          Mot de passe oublié
        </h2>
        <p className="text-gray-600 text-center mb-6">
          Saisissez votre adresse email pour recevoir un lien de réinitialisation.
        </p>

        {success ? (
          <div className="text-green-600 text-center flex flex-col items-center gap-4 p-4">
            <CheckCircle className="w-12 h-12" />
            <p className="text-lg">Email envoyé avec succès !</p>
            <p className="text-gray-600">
              Un email de réinitialisation a été envoyé à {email}.
            </p>
            <button
              onClick={() => navigate("/login")}
              className="mt-2 text-purple-600 hover:text-purple-800 transition-colors duration-200"
            >
              Retour à la connexion
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="text-red-600 text-center p-3 bg-red-50 rounded-md border border-red-200">
                {error}
              </div>
            )}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Adresse email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="votre@email.com"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg"
            >
              Envoyer le lien
            </button>

            <div className="text-center">
              <button
                onClick={() => navigate("/login")}
                className="text-purple-600 hover:text-purple-800 transition-colors duration-200"
              >
                Retour à la connexion
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;