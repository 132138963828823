import React, { useState, useEffect } from 'react';
import { getAllTestimonials, submitTestimonial } from '../api';
import BackgroundGrid from '../animations/Gridbg';

const Temoignage = () => {
  const [temoignages, setTemoignages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0); // Note sélectionnée
  const [hoverRating, setHoverRating] = useState(0); // Note au survol
  const [averageRating, setAverageRating] = useState(0); // Note moyenne
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Récupération des témoignages
  useEffect(() => {
    const fetchTemoignages = async () => {
      try {
        const data = await getAllTestimonials(); // Récupérer les témoignages depuis l'API

        console.log('Témoignages reçus :', data.data); // Inspectez la structure des données reçues
        setTemoignages(data.data);

        // Calcul de la note moyenne
        const totalRatings = data.data.reduce(
          (sum, temoignage) => sum + temoignage.note,
          0
        );
        const average =
          data.data.length > 0 ? totalRatings / data.data.length : 0;
        setAverageRating(average.toFixed(1)); // Limiter à une décimale
      } catch (error) {
        setError('Erreur lors de la récupération des témoignages.');
      }
    };

    fetchTemoignages();
  }, []);

  // Fonction handleSlideChange avec useCallback
  const handleSlideChange = React.useCallback(
    (direction) => {
      setIsTransitioning(true);
      setTimeout(() => {
        if (direction === 'next') {
          setCurrentSlide((prev) => (prev + 1) % temoignages.length);
        } else {
          setCurrentSlide(
            (prev) => (prev - 1 + temoignages.length) % temoignages.length
          );
        }
        setTimeout(() => setIsTransitioning(false), 50);
      }, 300);
    },
    [temoignages.length]
  );

  // Effet de défilement automatique avec dépendance mise à jour
  useEffect(() => {
    const timer = setInterval(() => {
      handleSlideChange('next');
    }, 5000);
    return () => clearInterval(timer);
  }, [handleSlideChange]);

  // Soumission d'un nouveau témoignage
  const handleSubmitTemoignage = async (formData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const payload = {
        nom_du_client: formData.nom_du_client,
        contenu_du_temoignage: formData.contenu_du_temoignage,
        note: formData.note,
      };

      const response = await submitTestimonial(payload);
      console.log('Témoignage soumis :', response);

      const updatedTestimonials = await getAllTestimonials();
      setTemoignages(updatedTestimonials.data);

      // Recalculer la note moyenne
      const totalRatings = updatedTestimonials.data.reduce(
        (sum, temoignage) => sum + temoignage.note,
        0
      );
      const average =
        updatedTestimonials.data.length > 0
          ? totalRatings / updatedTestimonials.data.length
          : 0;
      setAverageRating(average.toFixed(1));
    } catch (error) {
      console.error('Erreur lors de la soumission :', error.message);
      setError(error.message || 'Erreur lors de la soumission du témoignage.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Affichage des étoiles
  const renderStars = (note, isInteractive = false) => {
    return (
      <div className="inline-flex items-center justify-center bg-black rounded-3xl p-2">
        {Array(5)
          .fill(0)
          .map((_, index) => {
            const starIndex = index + 1;

            return (
              <span
                key={index}
                onMouseEnter={
                  isInteractive ? () => setHoverRating(starIndex) : undefined
                }
                onMouseLeave={
                  isInteractive ? () => setHoverRating(0) : undefined
                }
                onClick={isInteractive ? () => setRating(starIndex) : undefined}
                className={`text-xl cursor-pointer ${
                  starIndex <= (hoverRating || note)
                    ? 'text-yellow-400'
                    : 'text-gray-300'
                }`}
              >
                ★
              </span>
            );
          })}
      </div>
    );
  };

  const renderCarousel = () => {
    if (!temoignages.length) return null;

    return (
      <div className="relative overflow-hidden mx-auto">
        <div
          className={`flex transition-all duration-300 ease-in-out ${
            isTransitioning ? 'opacity-0' : 'opacity-100'
          }`}
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          {temoignages.map((temoignage) => (
            <div
              key={temoignage.id}
              className="w-full md:w-full flex-shrink-0 px-4"
            >
              <div className="relative bg-white p-6 rounded-lg shadow-lg h-full max-w-2xl mx-auto">
                {' '}
                {/* Ajout max-w-2xl et mx-auto */}
                <BackgroundGrid
                  color="#000000"
                  cellSize="25px"
                  strokeWidth="1px"
                  fade={true}
                  className="absolute inset-0"
                />
                <div className="relative z-10">
                  <div className="mb-4 flex justify-center">
                    {renderStars(temoignage.note)}
                  </div>
                  <p className="text-gray-700 mb-4 text-center">
                    "{temoignage.contenu_du_temoignage}"
                  </p>
                  <p className="font-bold text-right">
                    — {temoignage.nom_du_client}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Indicateurs */}
        <div className="absolute bottom-0 left-0 right-0 flex justify-center gap-2 pb-4 z-20">
          {temoignages.map((_, idx) => (
            <button
              key={idx}
              onClick={() => setCurrentSlide(idx)}
              className={`w-3 h-3 rounded-full transition-all ${
                idx === currentSlide ? 'bg-black scale-125' : 'bg-gray-300'
              }`}
              aria-label={`Aller à la slide ${idx + 1}`}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-100 py-8 sm:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6">Témoignages</h2>
        <p className="text-gray-600 text-sm sm:text-base mb-6 sm:mb-8">Ce que disent nos clients</p>

        {/* Note moyenne */}
        <div className="text-center mb-8 sm:mb-10">
          <h3 className="text-lg sm:text-xl  font-semibold">
            Note moyenne : {averageRating} / 5
            <span className="ml-2 text-gray-600 text-sm sm:text-base">
              ({temoignages.length} {temoignages.length > 1 ? 'avis' : 'avis'})
            </span>
          </h3>
          <div className="flex justify-center mt-2">
            {renderStars(Math.round(averageRating))}
          </div>
        </div>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {renderCarousel()}

        <div className="mt-12 max-w-xl mx-auto">
          <h3 className="text-xl sm:text-2xl font-bold mb-4">Partagez votre expérience</h3>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              const formData = {
                nom_du_client: e.target.nom_du_client.value,
                contenu_du_temoignage: e.target.contenu_du_temoignage.value,
                note: rating,
              };

              await handleSubmitTemoignage(formData);
              e.target.reset(); // Réinitialisez le formulaire
              setRating(0); // Réinitialisez la note
            }}
          >
            <div>
              <label
                htmlFor="nom_du_client"
                className="block text-sm sm:text-base text-gray-700 mb-2"
              >
                Nom
              </label>
              <input
                type="text"
                id="nom_du_client"
                name="nom_du_client"
                required
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <div>
              <label
                htmlFor="contenu_du_temoignage"
                className="block text-sm sm:text-base text-gray-700 mb-2"
              >
                Votre témoignage
              </label>
              <textarea
                id="contenu_du_temoignage"
                name="contenu_du_temoignage"
                required
                rows="4"
                className="w-full p-2 border rounded-lg"
              ></textarea>
            </div>
            <div>
              <label className="block text-sm sm:text-base text-gray-700 mb-1">Note</label>
              <div className="flex mb-4">{renderStars(rating, true)}</div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-black text-white px-4 sm:px-6 py-2 rounded-lg hover:bg-eastern disabled:opacity-50 text-sm sm:text-base"
            >
              {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Temoignage;
