import React, { useState } from 'react';

const CheckoutButton = ({ priceId, projectId, projectTitle }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCreateCheckoutSession = async () => {
    try {
      setLoading(true);
      setErrorMessage(null);
      console.log('🔍 Données envoyées:', { priceId, projectId });

      // Vérification des données
      if (!priceId) {
        console.error('❌ PriceId manquant');
        setErrorMessage('PriceId manquant. Impossible de procéder au paiement.');
        return;
      }

      // Collecter les informations client depuis localStorage si disponibles
      const savedCustomerInfo = localStorage.getItem('customerInfo');
      let customerInfo = savedCustomerInfo ? JSON.parse(savedCustomerInfo) : {};

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/projets/create-checkout-session`,
        {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            priceId,
            projetId: projectId,
            customerEmail: customerInfo.email,
            customerName: customerInfo.name,
            projectTitle
          }),
        }
      );

      console.log('📡 Statut de la réponse:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('❌ Erreur détaillée:', errorData);
        throw new Error(errorData.error || 'Impossible de créer une session de paiement.');
      }

      const data = await response.json();
      console.log('✅ Réponse reçue:', data);

      if (data.url) {
        // Sauvegarder l'ID de session pour la page de succès
        sessionStorage.setItem('checkoutSessionId', data.sessionId);
        window.location.href = data.url;
      } else {
        throw new Error('Lien de paiement manquant dans la réponse.');
      }
    } catch (error) {
      setErrorMessage('Une erreur est survenue lors de la création de la session de paiement.');
      console.error('Erreur détaillée:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {errorMessage && (
        <div className="bg-red-50 text-red-500 p-3 rounded-md text-sm">
          {errorMessage}
        </div>
      )}
      
      <button
        onClick={handleCreateCheckoutSession}
        disabled={loading}
        className={`
          bg-black text-white py-2 px-4 rounded
          hover:bg-eastern transition duration-300
          flex items-center justify-center gap-2
          ${loading ? 'opacity-50 cursor-not-allowed' : ''}
        `}
      >
        {loading ? (
          <>
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Traitement en cours...
          </>
        ) : (
          <>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>
            Acheter maintenant
          </>
        )}
      </button>
    </div>
  );
};

export default CheckoutButton;