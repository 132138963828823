import React from 'react';
import { FaLock, FaCalendarAlt, FaCreditCard } from 'react-icons/fa';

const PaiementSecurise = () => {
  return (
    <div className="container mt-16 sm:mt-32 mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6">
        Paiement Sécurisé
      </h1>
      <p className="text-sm sm:text-base text-gray-600 text-center mb-6 sm:mb-8 max-w-lg sm:max-w-3xl mx-auto">
        Toutes les transactions effectuées sur notre site sont protégées grâce à <strong>Stripe</strong>, une plateforme de paiement reconnue pour sa sécurité et sa fiabilité. 
        Vos informations bancaires sont encryptées et ne transitent jamais par nos serveurs.
      </p>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4 flex items-center">
          <FaCalendarAlt className="text-green-500 text-2xl mr-3" />
          Paiements Mensuels
        </h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Pour notre offre d'abonnement à 9,99€/mois, nous utilisons le système de paiement récurrent sécurisé de Stripe.
        </p>
        <ul className="list-disc pl-5 mt-4 text-sm sm:text-base text-gray-700">
          <li>Prélèvement automatique mensuel sécurisé</li>
          <li>Engagement de 18 mois (total de 180€)</li>
          <li>Possibilité de mettre à jour vos informations de paiement à tout moment</li>
          <li>Notifications automatiques avant chaque prélèvement</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4 flex items-center">
          <FaCreditCard className="text-green-500 text-2xl mr-3" />
          Gestion de votre Abonnement
        </h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Une fois votre abonnement activé, vous recevrez :
        </p>
        <ul className="list-disc pl-5 mt-4 text-sm sm:text-base text-gray-700">
          <li>Un email de confirmation avec les détails de votre abonnement</li>
          <li>Un accès à votre espace client pour gérer vos paiements</li>
          <li>Des factures mensuelles automatiques</li>
          <li>La possibilité de modifier vos informations bancaires en toute sécurité</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4 flex items-center">
          <FaLock className="text-green-500 text-2xl mr-3" />
          Sécurité des Transactions
        </h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Stripe est certifié PCI-DSS (Payment Card Industry Data Security Standard), garantissant une protection optimale de vos données personnelles et financières.
        </p>
        <ul className="list-disc pl-5 mt-4 text-sm sm:text-base text-gray-700">
          <li>Protection des données grâce à une technologie de chiffrement avancée.</li>
          <li>Conforme aux normes de sécurité les plus strictes (PCI-DSS).</li>
          <li>Paiement rapide et transparent via Stripe.</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">Logo Stripe</h2>
        <img
          src="https://res.cloudinary.com/dwcdxkpfk/image/upload/v1736382739/stripe_twabz5.webp"
          alt="Logo Stripe"
          className="w-32 mx-auto"
        />
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mb-4">Besoin d’aide ?</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          En cas de problème ou de question concernant votre paiement, veuillez nous contacter à l’adresse suivante :{' '}
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline">
            contact@vgomcreation.fr
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default PaiementSecurise;
