import React, { useContext, createContext, useState, useEffect } from 'react';
import { UserContext } from './UserContext';
import {
  toggleArticleLike,
  addComment,
  getComments,
  incrementView,
  API_URL,
} from '../api';

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { jwt } = useContext(UserContext);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/api/blogs?populate=*`);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des articles');
        }
        const data = await response.json();
        setArticles(data.data);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des articles:', err);
        setError('Impossible de charger les articles.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    console.log('Articles mis à jour dans le contexte :', articles);
  }, [articles]);

  const handleUpdateViewCount = async (articleId) => {
    try {
      const newViewCount = await incrementView(articleId);
      console.log(`Nouvelle vue pour l'article ${articleId} :`, newViewCount);
      if (newViewCount !== null) {
        setArticles((prevArticles) =>
          prevArticles.map((a) =>
            a.id === articleId ? { ...a, vue_count: newViewCount } : a
          )
        );
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des vues:', error);
    }
  };

  const handleLikeToggle = async (articleId) => {
    try {
      const response = await toggleArticleLike(articleId);

      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                likes_count: response.data.likes_count,
                hasLiked: response.data.hasLiked,
              }
            : article
        )
      );
      setLikeInLocalStorage(articleId, response.data.hasLiked);
    } catch (error) {
      console.error('Erreur lors du toggle du like:', error);
    }
  };

  const setLikeInLocalStorage = (articleId, hasLiked) => {
    const storedLikes = JSON.parse(localStorage.getItem('likedArticles')) || {};
    storedLikes[articleId] = hasLiked;
    localStorage.setItem('likedArticles', JSON.stringify(storedLikes));
  };

  const setCommentCountInLocalStorage = (articleId, commentCount) => {
    const storedComments =
      JSON.parse(localStorage.getItem('commentCounts')) || {};
    storedComments[articleId] = commentCount;
    localStorage.setItem('commentCounts', JSON.stringify(storedComments));
  };

  const handleAddComment = async (articleId, commentData) => {
    if (!jwt) {
      console.error("Vous devez être connecté pour ajouter un commentaire.");
      alert("Veuillez vous connecter pour ajouter un commentaire.");
      return;
    }

    try {
      const newComment = await addComment(articleId, commentData, jwt);
      console.log('Nouveau commentaire créé :', newComment);

      const updatedComments = await getComments(articleId);
      console.log('Commentaires mis à jour :', updatedComments);

      const updatedCommentCount = updatedComments.length;

      setArticles((prevArticles) =>
        prevArticles.map((a) =>
          a.id === articleId
            ? {
                ...a,
                commentaire_count: updatedCommentCount,
                comments: updatedComments,
              }
            : a
        )
      );

      setCommentCountInLocalStorage(articleId, updatedCommentCount);
    } catch (error) {
      console.error(
        'Erreur lors de l’ajout du commentaire:',
        error.response?.data || error.message
      );
    }
  };

  const updateCommentCount = (articleId, newCount) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              commentaire_count: newCount,
            }
          : article
      )
    );
  };

  return (
    <BlogContext.Provider
      value={{
        articles,
        loading,
        error,
        handleUpdateViewCount,
        handleLikeToggle,
        handleAddComment,
        getComments,
        updateCommentCount,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
