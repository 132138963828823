import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaUser, FaEuroSign, FaFileInvoice } from 'react-icons/fa';
import { HiMail } from 'react-icons/hi';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const sessionId = searchParams.get('session_id');
      if (!sessionId) {
        setError('Aucun identifiant de session trouvé.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/projets/retrieve-session/${sessionId}`
        );

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de la commande.');
        }

        const data = await response.json();
        setOrderDetails(data);
      } catch (err) {
        console.error('Erreur détails commande:', err);
        setError('Impossible de récupérer les détails de votre commande.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [searchParams]);

  if (error) {
    return (
      <div className="min-h-screen bg-red-50 flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg text-center">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h1 className="text-2xl font-bold text-red-600 mb-4">Une erreur est survenue</h1>
          <p className="text-gray-700">{error}</p>
          <button
            onClick={() => window.location.href = '/'}
            className="mt-6 px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-600 mx-auto"></div>
          <p className="mt-4 text-lg text-gray-600">Chargement des détails de votre commande...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white py-12 px-4">
      <div className="max-w-2xl mx-auto">
        <div className="text-center mb-12">
          <FaCheckCircle className="text-green-600 text-6xl mx-auto mb-6" />
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Paiement réussi !</h1>
          <p className="text-xl text-gray-600">
            Merci pour votre commande, <span className="font-semibold">{orderDetails.customer_name}</span> !
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Détails de la transaction</h2>
          
          <div className="space-y-6">
            <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <FaUser className="text-gray-500 text-xl" />
              <div>
                <p className="text-sm text-gray-500">Client</p>
                <p className="text-gray-700 font-medium">{orderDetails.customer_name}</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <HiMail className="text-gray-500 text-xl" />
              <div>
                <p className="text-sm text-gray-500">Email de confirmation</p>
                <p className="text-gray-700 font-medium">{orderDetails.customer_email}</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <FaEuroSign className="text-gray-500 text-xl" />
              <div>
                <p className="text-sm text-gray-500">Montant payé</p>
                <p className="text-gray-700 font-medium">
                  {(orderDetails.amount_total / 100).toFixed(2)} {orderDetails.currency.toUpperCase()}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <FaFileInvoice className="text-gray-500 text-xl" />
              <div>
                <p className="text-sm text-gray-500">Statut</p>
                <p className="text-green-600 font-medium">Commande validée</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-8">
          <div className="flex items-start gap-3">
            <div className="text-blue-500 text-xl mt-1">ℹ️</div>
            <div>
              <p className="text-blue-800">
                Un email de confirmation avec votre facture a été envoyé à votre adresse email.
                Veuillez vérifier votre boîte de réception.
              </p>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            onClick={() => window.location.href = '/'}
            className="inline-flex items-center px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition"
          >
            <span>Retour à l'accueil</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;