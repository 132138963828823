import React from 'react';

const CGU = () => {
  return (
    <div className="container mt-16 sm:mt-32 mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-4 sm:mb-6">
        Conditions Générales d'Utilisation (CGU)
      </h1>
      <p className="text-sm sm:text-base text-gray-600 text-center mb-6 sm:mb-8 max-w-lg sm:max-w-3xl mx-auto">
        Les présentes Conditions Générales d’Utilisation (CGU) définissent les règles d’utilisation du site internet VGom Creation. En accédant au site, vous acceptez ces conditions dans leur intégralité.
      </p>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          1. Objet des CGU
        </h2>
        <p className="text-sm sm:text-base">
          Les présentes CGU définissent les règles d'utilisation des services proposés par VGom Creation, ainsi que les droits et obligations des utilisateurs et de VGom Creation.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          2. L’espace membre
        </h2>
        <p className="text-sm sm:text-base">
          Le site internet ne comprend pas d’espace membre. Aucune création de compte ou gestion personnelle n’est requise pour accéder aux services du site.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          3. L'éditeur du site
        </h2>
        <p className="text-sm sm:text-base">
          <strong>Nom et prénom de l’éditeur :</strong> Vaitilingom Vincent
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse de domiciliation :</strong> 7 Rue de la Louette, 91150 Étampes, France
        </p>
        <p className="text-sm sm:text-base">
          <strong>Numéro de téléphone :</strong> 07 67 60 34 49
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse e-mail :</strong>{' '}
          <a href="mailto:vaitilingomvincent@gmail.com" className="text-blue-500 underline">
            vaitilingomvincent@gmail.com
          </a>
        </p>
        <p className="text-sm sm:text-base">
          <strong>Régime juridique :</strong> Non assujetti au RCS ou au Répertoire des métiers.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          4. L’hébergeur du site
        </h2>
        <p className="text-sm sm:text-base">
          <strong>Nom du site de l’hébergeur :</strong> Vercel
        </p>
        <p className="text-sm sm:text-base">
          <strong>Dénomination sociale de l’hébergeur :</strong> Vercel Inc.
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse du siège social de l’hébergeur :</strong> 340 S Lemon Ave #4133, Walnut, CA 91789, United States
        </p>
        <p className="text-sm sm:text-base">
          <strong>Numéro de téléphone de l’hébergeur :</strong> Informations disponibles via{' '}
          <a href="https://support.vercel.com" className="text-blue-500 underline">
            support.vercel.com
          </a>
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          5. Services du site internet
        </h2>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base">
          <li>Mise à disposition de modèles de sites web prêts à l'emploi.</li>
          <li>Vente de services de personnalisation pour les sites web.</li>
          <li>Formulaire de demande de devis pour des prestations sur mesure (création, maintenance, hébergement).</li>
          <li>Accès à des guides d’installation et des ressources numériques.</li>
          <li>Paiement en ligne via une plateforme sécurisée (Stripe).</li>
        </ul>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          6. Collecte de données
        </h2>
        <p className="text-sm sm:text-base">
          Le site collecte des données personnelles dans les cas suivants :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base">
          <li>Lors de l’utilisation du formulaire de contact ou de demande de devis.</li>
          <li>Lors du passage d’une commande (nom, prénom, adresse email, téléphone, et informations nécessaires au paiement).</li>
        </ul>
        <p className="text-sm sm:text-base">
          <strong>Finalités de la collecte :</strong>
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base">
          <li>Traitement des commandes et suivi des prestations.</li>
          <li>Communication avec les clients.</li>
          <li>Analyse de navigation via des cookies (si applicable).</li>
        </ul>
        <p className="text-sm sm:text-base">
          Les utilisateurs peuvent exercer leurs droits d’accès, rectification, suppression, ou opposition en contactant :{' '}
          <a href="mailto:vaitilingomvincent@gmail.com" className="text-blue-500 underline">
            vaitilingomvincent@gmail.com
          </a>.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          7. Propriété intellectuelle
        </h2>
        <p className="text-sm sm:text-base">
          Tous les contenus du site (textes, images, logos, designs) sont la propriété exclusive de VGom Creation. Toute reproduction, distribution ou modification non autorisée est interdite et pourra faire l’objet de poursuites.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          8. Cookies
        </h2>
        <p className="text-sm sm:text-base">
          Le site n’utilise actuellement aucun cookie nécessitant l’accord des utilisateurs. Si des cookies d’analyse ou publicitaires sont ajoutés ultérieurement, une politique de consentement sera mise en place conformément au RGPD.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          9. Publications des utilisateurs
        </h2>
        <p className="text-sm sm:text-base">
          Le site ne permet pas aux utilisateurs de publier du contenu. Toute tentative de publication non autorisée sera supprimée immédiatement.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          10. Litiges
        </h2>
        <p className="text-sm sm:text-base">
          En cas de litige, les utilisateurs peuvent contacter le service de médiation suivant :
        </p>
        <p className="text-sm sm:text-base">
          <strong>Nom :</strong> CM2C (Centre de Médiation de la Consommation de Conciliateurs de Justice)
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse :</strong> 14 rue Saint Jean, 75017 Paris, France
        </p>
        <p className="text-sm sm:text-base">
          <strong>Email :</strong>{' '}
          <a href="mailto:cm2c@cm2c.net" className="text-blue-500 underline">
            cm2c@cm2c.net
          </a>
        </p>
        <p className="text-sm sm:text-base">
          <strong>Site internet :</strong>{' '}
          <a href="https://www.cm2c.net" className="text-blue-500 underline">
            https://www.cm2c.net
          </a>
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 mb-2">
          11. Date de mise en vigueur
        </h2>
        <p className="text-sm sm:text-base">
          Les présentes CGU sont entrées en vigueur le 1er janvier 2025.
        </p>
      </section>
    </div>
  );
};

export default CGU;

