import React from 'react';

const CGV = () => {
  return (
    <div className="container mt-16 sm:mt-32 lg:mt-32 mx-auto px-4 py-6 sm:py-8">
      {/* Titre principal */}
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6 sm:mb-8">
        Conditions Générales de Vente (CGV)
      </h1>

      {/* Texte introductif */}
      <p className="text-sm sm:text-base text-gray-600 text-center mb-8 sm:mb-10 max-w-lg sm:max-w-3xl mx-auto">
        Les présentes Conditions Générales de Vente régissent les transactions
        réalisées sur le site VGom Creation. En effectuant une commande, vous
        acceptez ces conditions dans leur intégralité.
      </p>

      {/* Sections */}
      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          1. Identification du Prestataire
        </h2>
        <p className="text-sm sm:text-base">
          <strong>Nom de l’entreprise :</strong> VGom Creation
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse :</strong> 7 Rue de la Louette, 91150 Étampes, France
        </p>
        <p className="text-sm sm:text-base">
          <strong>Email :</strong>{' '}
          <a
            href="mailto:contact@vgomcreation.fr"
            className="text-blue-500 underline"
          >
            contact@vgomcreation.fr
          </a>
        </p>
        <p className="text-sm sm:text-base">
          <strong>Téléphone :</strong> 07 67 60 34 49
        </p>
        <p className="text-sm sm:text-base">
          <strong>Numéro SIRET :</strong> 93380665500017
        </p>
        <p className="text-sm sm:text-base">
          <strong>Hébergeur du site :</strong> Vercel
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          2. Objet des CGV
        </h2>
        <p className="text-sm sm:text-base">
          Les présentes Conditions Générales de Vente (CGV) s'appliquent à la
          vente des produits et services proposés par VGom Creation, notamment :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 mt-2 text-sm sm:text-base">
          <li>Des sites web prêts à l'emploi et leurs personnalisations.</li>
          <li>
            Des prestations de création de sites web sur mesure, d’hébergement
            et de maintenance.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          3. Produits et Services
        </h2>
        <p className="text-sm sm:text-base">VGom Creation propose :</p>
        <ul className="list-disc ml-4 sm:ml-6 mt-2 text-sm sm:text-base">
          <li>
            <strong>Sites web prêts à l'emploi :</strong> Modèles préconçus de
            sites internet adaptés à différentes activités (e-commerce, blogs,
            etc.).
          </li>
          <li>
            <strong>Personnalisation :</strong> Adaptation des couleurs, logos,
            et contenus selon les besoins du client.
          </li>
          <li>
            <strong>Services sur mesure :</strong> Création de sites internet
            spécifiques, hébergement sécurisé et optimisation SEO.
          </li>
          <li>
            <strong>Offre Site Vitrine à 9,99€/mois :</strong>
            <ul className="list-circle ml-6 mt-1">
              <li>Une page de présentation professionnelle</li>
              <li>Design responsive (mobile, tablette, ordinateur)</li>
              <li>Mises à jour hebdomadaires incluses</li>
              <li>Hébergement et maintenance inclus</li>
              <li>Engagement de 18 mois (180€ total)</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          4. Modalités de commande et livraison
        </h2>
        <p className="text-sm sm:text-base">
          Les fichiers digitaux sont livrés sous 48 heures après validation de
          la commande. En cas de personnalisation, le délai est spécifié dans le
          devis.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          5. Prix
        </h2>
        <p className="text-sm sm:text-base">
          Les prix sont affichés en euros, toutes taxes comprises (TTC). Ils
          incluent les frais de personnalisation, le cas échéant, et sont
          définis clairement avant la validation de la commande.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          6. Paiement
        </h2>
        <p className="text-sm sm:text-base">
          Les paiements sont effectués via Stripe pour une sécurité optimale.
          Les moyens acceptés incluent les cartes bancaires.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          7. Engagement pour les abonnements
        </h2>
        <p className="text-sm sm:text-base">
          L'offre Site Vitrine à 9,99€/mois est soumise à un engagement de 18 mois. 
          Le montant total de l'engagement est de 180€, payable mensuellement.
        </p>
        <p className="text-sm sm:text-base mt-2">
          À l'issue de la période d'engagement de 18 mois :
        </p>
        <ul className="list-disc ml-6 mt-2 text-sm sm:text-base">
          <li>En cas de poursuite de l'abonnement : maintien des services de mise à jour et maintenance</li>
          <li>En cas d'arrêt : conservation du site en l'état, sans mises à jour incluses</li>
        </ul>
        <p className="text-sm sm:text-base mt-2">
          Les pages supplémentaires peuvent être ajoutées moyennant un paiement ponctuel, 
          dont le montant sera établi sur devis.
        </p>
      </section>


      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          8. Fonctionnalités supplémentaires et paiements ponctuels
        </h2>
        <p className="text-sm sm:text-base">
          Les clients peuvent demander l’ajout de fonctionnalités
          supplémentaires à leur site (ex. ajout de pages, intégration de
          services tiers, optimisation SEO avancée). Ces ajouts feront l’objet
          d’un devis séparé et seront facturés en tant que paiement ponctuel.
        </p>
        <p className="text-sm sm:text-base">
          Les délais de livraison pour ces fonctionnalités seront précisés lors
          de la validation du devis. Toute modification ou demande
          supplémentaire non incluse dans le devis initial entraînera des coûts
          additionnels.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          9. Résiliation et remboursement
        </h2>
        <p className="text-sm sm:text-base">
          Le client peut résilier son abonnement à tout moment après la période
          d'engagement initiale (12 mois) en envoyant une notification écrite à
          l’adresse email{' '}
          <a
            href="mailto:contact@vgomcreation.fr"
            className="text-blue-500 underline"
          >
            contact@vgomcreation.fr
          </a>
          .
        </p>
        <p className="text-sm sm:text-base">
          Aucun remboursement ne sera effectué pour les paiements déjà
          effectués, sauf en cas d'erreur manifeste de VGom Creation ou
          d'annulation d'une prestation non encore réalisée.
        </p>
        <p className="text-sm sm:text-base">
          En cas de non-respect des conditions par le client (ex. non-paiement,
          fourniture de contenus illégaux), VGom Creation se réserve le droit de
          résilier immédiatement le contrat sans préavis.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          10. Données personnelles pour les abonnements
        </h2>
        <p className="text-sm sm:text-base">
          Dans le cadre des abonnements, VGom Creation collecte et utilise des
          données personnelles telles que le nom, l'adresse email, et les
          informations de paiement du client. Ces données sont nécessaires pour
          la gestion des paiements récurrents et la fourniture des services
          associés.
        </p>
        <p className="text-sm sm:text-base">
          Les données sont traitées conformément à notre{' '}
          <a
            href="/politique-confidentialite"
            className="text-blue-500 underline"
          >
            Politique de Confidentialité
          </a>
          . Le client peut exercer ses droits d’accès, de rectification et de
          suppression en contactant notre support à{' '}
          <a
            href="mailto:contact@vgomcreation.fr"
            className="text-blue-500 underline"
          >
            contact@vgomcreation.fr
          </a>
          .
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          11. Droit de rétractation
        </h2>
        <p className="text-sm sm:text-base">
          Conformément à l'article L221-28 du Code de la consommation, le droit
          de rétractation ne s'applique pas aux produits numériques livrés
          immédiatement.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          12. Garanties
        </h2>
        <p className="text-sm sm:text-base">
          Les produits bénéficient des garanties légales, incluant la garantie
          de conformité et la garantie contre les vices cachés.
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          13. Litiges
        </h2>
        <p className="text-sm sm:text-base">
          En cas de litige, le client peut contacter le service de médiation :
        </p>
        <p className="text-sm sm:text-base">
          <strong>Service :</strong> CM2C (Centre de Médiation de la
          Consommation de Conciliateurs de Justice)
        </p>
        <p className="text-sm sm:text-base">
          <strong>Adresse :</strong> 14 rue Saint Jean, 75017 Paris, France
        </p>
        <p className="text-sm sm:text-base">
          <strong>Email :</strong>{' '}
          <a href="mailto:cm2c@cm2c.net" className="text-blue-500 underline">
            cm2c@cm2c.net
          </a>
        </p>
        <p className="text-sm sm:text-base">
          <strong>Site :</strong>{' '}
          <a href="https://www.cm2c.net" className="text-blue-500 underline">
            https://www.cm2c.net
          </a>
        </p>
      </section>

      <section>
        <h2 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-3">
          14. Date de mise en vigueur
        </h2>
        <p className="text-sm sm:text-base">
          Les présentes CGV sont entrées en vigueur le 1 janvier 2025.
        </p>
      </section>
    </div>
  );
};

export default CGV;
