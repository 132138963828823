import React, { useState } from 'react';
import { Eye, EyeOff, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { register } from '../api';

function Inscription() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const passwordCriteria = {
    length: formData.password.length >= 8,
    uppercase: /[A-Z]/.test(formData.password),
    lowercase: /[a-z]/.test(formData.password),
    number: /\d/.test(formData.password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(formData.password),
  };

  const allCriteriaMet = Object.values(passwordCriteria).every(Boolean);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    if (!allCriteriaMet) {
      setError("Le mot de passe ne respecte pas tous les critères.");
      return;
    }

    try {
      const response = await register(formData); // Passage de formData directement
      if (response) {
        setSuccess(true);
        setEmailSent(true);
        setTimeout(() => {
          navigate('/email-verification-prompt');
        }, 2000);
      }
    } catch (err) {
      setError(err.response?.data?.error?.message || "Erreur lors de l'inscription");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
      <div className="w-full max-w-md sm:max-w-lg lg:max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2 sm:mb-4 text-center">
          Créer un compte
        </h2>
        <p className="text-sm sm:text-base text-gray-500 text-center mb-4 sm:mb-6">
          Rejoignez-nous en créant votre compte. C'est rapide et facile !
        </p>

        {error && (
          <p className="text-sm sm:text-base text-red-500 text-center mb-4">
            {error}
          </p>
        )}
        {success && (
          <div className="flex items-center justify-center text-green-500 mb-4 text-sm sm:text-base">
            <CheckCircle className="mr-2" />
            <span>Inscription réussie ! Redirection vers la connexion...</span>
          </div>
        )}
        {emailSent && (
          <div className="text-blue-600 text-center text-sm sm:text-base mb-4">
            <p>Un email de confirmation a été envoyé à votre adresse.</p>
            <p>
              Veuillez vérifier votre boîte de réception pour activer votre
              compte.
            </p>
          </div>
        )}

        {!success && !emailSent && (
          <form onSubmit={handleRegister} className="space-y-4 sm:space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
                >
                  Prénom
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
                >
                  Nom
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="username"
                className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
              >
                Nom d'utilisateur
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
              >
                E-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
              >
                Mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-lg pr-12 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              <ul className="mt-2 text-sm space-y-1">
                <li className={`flex items-center ${passwordCriteria.length ? 'text-green-600' : 'text-gray-500'}`}>
                  <CheckCircle className="mr-2" size={16} />
                  Au moins 8 caractères
                </li>
                <li className={`flex items-center ${passwordCriteria.uppercase ? 'text-green-600' : 'text-gray-500'}`}>
                  <CheckCircle className="mr-2" size={16} />
                  Une lettre majuscule
                </li>
                <li className={`flex items-center ${passwordCriteria.lowercase ? 'text-green-600' : 'text-gray-500'}`}>
                  <CheckCircle className="mr-2" size={16} />
                  Une lettre minuscule
                </li>
                <li className={`flex items-center ${passwordCriteria.number ? 'text-green-600' : 'text-gray-500'}`}>
                  <CheckCircle className="mr-2" size={16} />
                  Un chiffre
                </li>
                <li className={`flex items-center ${passwordCriteria.specialChar ? 'text-green-600' : 'text-gray-500'}`}>
                  <CheckCircle className="mr-2" size={16} />
                  Un caractère spécial
                </li>
              </ul>
            </div>

            <button
              type="submit"
              disabled={!allCriteriaMet}
              className="w-full bg-gradient-to-br from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg disabled:opacity-50"
            >
              S'inscrire
            </button>
          </form>
        )}
        <div className="text-center mt-6">
          <p className="text-sm sm:text-base text-gray-600">
            Déjà un compte ?{' '}
            <button
              className="text-purple-600 hover:underline"
              onClick={() => navigate('/login')}
            >
              Connectez-vous
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Inscription;
