import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className="container mt-16 sm:mt-32 mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-center">
        Politique de confidentialité
      </h1>
      <p className="text-sm sm:text-base text-gray-600 text-center mb-6 sm:mb-8 max-w-lg sm:max-w-3xl mx-auto">
        Cette politique de confidentialité explique comment VGom Creation collecte, utilise, partage et protège vos informations personnelles lorsque vous visitez notre site web ou utilisez nos services.
      </p>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">2. Utilisation des informations</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Vos informations personnelles sont utilisées pour :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700">
          <li>Traiter vos commandes et paiements via des prestataires comme Stripe ;</li>
          <li>Répondre à vos demandes et assurer le support client ;</li>
          <li>Améliorer nos services et personnaliser votre expérience utilisateur ;</li>
          <li>Vous envoyer des communications marketing si vous avez donné votre consentement explicite.</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">3. Partage des informations</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Nous partageons vos informations personnelles uniquement dans les cas suivants :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700">
          <li>Avec nos partenaires de paiement (par ex., Stripe) pour sécuriser vos transactions ;</li>
          <li>Avec des prestataires tiers de confiance pour l’hébergement et l’analyse de données ;</li>
          <li>Pour se conformer à des obligations légales ou des demandes des autorités compétentes.</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">4. Durée de conservation des données</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Vos données personnelles sont conservées aussi longtemps que nécessaire pour les finalités décrites dans cette politique :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700">
          <li>Les données liées aux commandes sont conservées conformément aux obligations légales (par ex., 10 ans pour des documents comptables).</li>
          <li>Les données marketing sont conservées jusqu’à retrait de consentement ou après une période d’inactivité de 3 ans.</li>
        </ul>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">5. Vos droits</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Conformément au RGPD, vous disposez des droits suivants :
        </p>
        <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700">
          <li>Accéder à vos données personnelles ;</li>
          <li>Demander la rectification ou la suppression de vos données ;</li>
          <li>Limiter ou vous opposer au traitement de vos données ;</li>
          <li>Retirer votre consentement à tout moment pour les communications marketing ;</li>
          <li>Déposer une plainte auprès de la CNIL (Commission Nationale de l'Informatique et des Libertés) si nécessaire.</li>
        </ul>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Pour exercer vos droits, contactez-nous à <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline">contact@vgomcreation.fr</a>.
        </p>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">6. Modifications de la politique</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page. 
          Nous vous encourageons à consulter cette politique régulièrement pour rester informé.
        </p>
      </section>

      <section className="mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">7. Contact</h2>
        <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
          Pour toute question ou demande relative à cette politique, vous pouvez nous contacter par e-mail à
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline ml-1">contact@vgomcreation.fr</a>.
        </p>
      </section>
    </div>
  );
};

export default PolitiqueConfidentialite;

