import axios from 'axios';

// URL de l'API depuis les variables d'environnement
export const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  console.error('API_URL is not defined in the environment variables.');
  throw new Error('Missing REACT_APP_API_URL in .env file');
}

// Crée une instance Axios
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true, // Ajoute les cookies si nécessaires
});

// Intercepteur pour inclure le JWT
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt');
    console.log('Request Config:', config);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepteur pour gérer les erreurs
api.interceptors.response.use(
  (response) => {
    console.log('Response:', response);
    return response;
  },
  (error) => {
    console.error('Response Error:', error.response);
    if (error.response && error.response.status === 401) {
      // Déconnexion automatique en cas de 401
      logout();
    }
    return Promise.reject(error);
  }
);

// Gestion des erreurs globales
const handleError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
  } else {
    console.error('Network Error:', error.message);
  }
  throw error;
};

// Fonction pour se connecter
export const login = async (formData) => {
  console.log('Début de login avec:', formData);
  try {
    const response = await api.post('/api/auth/local', {
      identifier: formData.identifier,
      password: formData.password,
    });
    console.log('Login réussi:', response.data);

    // Configurer le header d'autorisation pour toutes les futures requêtes
    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
    console.log('Header Authorization mis à jour:', api.defaults.headers.common['Authorization']);
    
    // Stocker les informations
    localStorage.setItem('jwt', response.data.jwt);
    localStorage.setItem('user', JSON.stringify(response.data.user));

    return response.data;
  } catch (error) {
    console.log('Erreur de login:', {
      status: error.response?.status,
      message: error.response?.data?.error?.message
    });
    throw error;
  }
};

// Fonction pour s'inscrire
export const register = async (formData) => {
  try {
    const response = await api.post('/api/auth/local/register', {
      username: formData.username,
      email: formData.email,
      password: formData.password,
      lastName: formData.lastName,
      firstName: formData.firstName,
    });
    
    localStorage.setItem('jwt', response.data.jwt);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Fonction pour obtenir tous les projets
export const getAllProjects = async () => {
  try {
    // Ajout du filtre vendable
    const response = await api.get('/api/projets?populate=*');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Fonction pour obtenir un projet spécifique
export const getProject = async (id) => {
  try {
    const response = await api.get(`/api/projets/${id}?populate=*`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Fonction pour efectuer un paiement
export const createPaymentIntent = async (amount, currency) => {
  try {
    const response = await axios.post(`/api/payment-intent`, {
      amount,
      currency,
    });

    return response.data; // Renvoie le clientSecret
  } catch (error) {
    console.error(
      'Erreur lors de la création du Payment Intent :',
      error.response || error.message
    );
    return { error: error.message };
  }
};

// Fonction pour envoyer un message via Web3Forms
export const sendMessage = async (formData) => {
  const apiKey = process.env.REACT_APP_WEB3FORM_API_KEY;

  const response = await fetch('https://api.web3forms.com/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_key: apiKey,
      ...formData,
    }),
  });

  if (!response.ok) {
    throw new Error('Une erreur est survenue lors de l’envoi du message.');
  }

  return response.json();
};

// Fonction pour récupérer tous les témoignages
export const getAllTestimonials = async () => {
  try {
    const response = await api.get('/api/temoignages');
    return response.data;
  } catch (error) {
    console.error(
      'Erreur lors de la récupération des témoignages:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fonction pour soumettre un nouveau témoignage
export const submitTestimonial = async (formData) => {
  try {
    const payload = {
      data: {
        nom_du_client: formData.nom_du_client,
        contenu_du_temoignage: formData.contenu_du_temoignage,
        note: formData.note,
      },
    };

    console.log('Payload envoyé à Strapi:', payload); // Debug : Vérifiez les données envoyées

    const response = await api.post('/api/temoignages', payload);
    return response.data;
  } catch (error) {
    console.error(
      'Erreur API lors de la soumission du témoignage :',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fonction pour obtenir tous les articles de blog
export const getAllPosts = async () => {
  try {
    const response = await api.get('/api/blogs?populate=*');
    const currentUserId = getCurrentUserId();
    const articles = response.data.data.map((article) => ({
      ...article.attributes,
      id: article.id,
      hasLiked:
        article.attributes.likes_users?.some(
          (user) => user.id === currentUserId
        ) || false,
    }));
    return articles;
  } catch (error) {
    console.error('Erreur lors du chargement des articles:', error);
    throw error;
  }
};

// Fonction pour obtenir l'ID du user actuel
const getCurrentUserId = () => {
  return localStorage.getItem('currentUserId');
};

// Fonction pour incrémenter le compteur de vues
export const incrementView = async (blogId) => {
  try {
    const response = await api.post(`/api/blogs/${blogId}/increment-view`);
    console.log(
      'Réponse backend après incrémentation des vues :',
      response.data
    );
    return response.data.data.vue_count; // Assurez-vous que le backend retourne `vue_count` dans la réponse
  } catch (error) {
    console.error('Erreur lors de l’incrémentation des vues:', error);
    return 0; // Retourne 0 en cas d’erreur
  }
};

// Fonction pour liker un post
export const likePost = async (postId, likedByUser) => {
  try {
    const response = await api.put(`/api/blogs/${postId}`, {
      data: {
        likedBy: likedByUser,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Fonction pour obtenir un article spécifique
export const toggleArticleLike = async (articleId) => {
  try {
    const response = await api.post(`/api/blogs/${articleId}/toggle-like`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors du toggle du like:', error);
    throw error; // On propage l'erreur pour la gérer dans le context
  }
};

// Fonction pour incrémenter le compteur de likes
export const incrementLike = async (blogId) => {
  try {
    const response = await api.post(`/api/blogs/${blogId}/increment-like`);
    return response.data.data.likes_count; // Assurez-vous que le backend retourne `likes_count` dans la réponse
  } catch (error) {
    console.error('Erreur lors de l’incrémentation des likes:', error);
    return 0; // Retourne 0 en cas d’erreur
  }
};

// Fonction pour créer un commentaire
export const addComment = async (blogId, commentData) => {
  try {
    const payload = {
      data: {
        auteur: commentData.auteur,
        contenu: commentData.contenu,
        date: commentData.date,
        blog: blogId, // Relation avec le blog
      },
    };
    const response = await api.post('/api/commentaires', payload);
    return response.data;
  } catch (error) {
    console.error('Erreur détaillée:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      payload: error.config?.data, // Pour voir ce qui a été envoyé
    });
    throw error;
  }
};

// Fonction pour récupérer les commentaires d'un article
export const getComments = async (blogId) => {
  try {
    const response = await axios.get(
      `${API_URL}/api/commentaires?filters[blog][id][$eq]=${blogId}`
    );
    return response.data.data;
  } catch (error) {
    console.error(
      'Erreur lors de la récupération des commentaires :',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fonction pour envoyer un email de réinitialisation de mot de passe
export const sendForgotPasswordEmail = async (email) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
    email,
  });
  return response.data;
};

// Fonction pour réinitialiser le mot de passe
export const resetPassword = async ({ code, password, passwordConfirmation }) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/reset-password`, {
      code,
      password,
      passwordConfirmation,
    });
    return response.data;
  } catch (error) {
    console.error('Erreur resetPassword:', error.response || error);
    throw error;  // Renvoie l'erreur pour la gestion dans le composant
  }
};

// Fonction pour déconnecter l'utilisateur
export const logout = () => {
  localStorage.removeItem('jwt'); // Supprime le JWT du localStorage
  window.location.href = '/login'; // Redirige l'utilisateur vers la page de connexion
  localStorage.removeItem('jwt');
  localStorage.removeItem('user');
};

export default api;
