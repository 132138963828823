import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, ArrowLeft, RefreshCw } from "lucide-react";

function EmailVerificationPrompt() {
 const navigate = useNavigate();

 return (
   <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
     <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
       <div className="flex flex-col items-center space-y-6">
         {/* Icône animée */}
         <div className="relative">
           <Mail size={64} className="text-purple-600" />
           <div className="absolute -top-1 -right-1">
             <div className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-purple-400 opacity-75"></div>
             <div className="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></div>
           </div>
         </div>

         <h2 className="text-2xl font-bold text-gray-800 text-center">
           Vérifiez votre email
         </h2>

         <div className="text-center space-y-4">
           <p className="text-gray-600">
             Nous vous avons envoyé un e-mail de confirmation.
             Veuillez vérifier votre boîte de réception et suivre le lien pour activer votre compte.
           </p>
           
           <p className="text-sm text-gray-500">
             N'oubliez pas de vérifier votre dossier spam si vous ne trouvez pas l'email.
           </p>
         </div>

         <div className="flex gap-4 mt-4">
           <button
             onClick={() => window.location.reload()}
             className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-800 to-indigo-600 text-white rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg"
           >
             <RefreshCw size={20} />
             Actualiser
           </button>

           <button
             onClick={() => navigate('/login')}
             className="flex items-center gap-2 px-4 py-2 border border-purple-600 text-purple-600 rounded-lg font-medium transition-all duration-300 hover:bg-purple-50"
           >
             <ArrowLeft size={20} />
             Retour
           </button>
         </div>
       </div>
     </div>
   </div>
 );
}

export default EmailVerificationPrompt;