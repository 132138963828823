import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Configuration Axios inchangée ...

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // États existants
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Nouvel état pour gérer le chargement
  const [isAuthChecking, setIsAuthChecking] = useState(true);

  const loginUser = (userData, token) => {
    setUser(userData);
    setJwt(token);
    setIsAuthenticated(true);
    localStorage.setItem("jwt", token);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const logoutUser = () => {
    setUser(null);
    setJwt(null);
    setIsAuthenticated(false);
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
  };

  // Effet modifié pour gérer le chargement
  useEffect(() => {
    const checkAuth = async () => {
      setIsAuthChecking(true);
      try {
        const storedJwt = localStorage.getItem("jwt");
        const storedUser = localStorage.getItem("user");

        if (storedJwt && storedUser) {
          setJwt(storedJwt);
          setUser(JSON.parse(storedUser));
          setIsAuthenticated(true);
          
          // Optionnel : Vérifier la validité du token avec le backend
          // await axios.get('/api/auth/verify-token');
        }
      } catch (error) {
        // En cas d'erreur, déconnecte l'utilisateur
        logoutUser();
      } finally {
        // Dans tous les cas, termine la vérification
        setIsAuthChecking(false);
      }
    };

    checkAuth();
  }, []);

  // Intercepteur de réponses modifié
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logoutUser();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  return (
    <UserContext.Provider
      value={{
        user,
        jwt,
        isAuthenticated,
        isAuthChecking, // Nouvel état exposé
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};