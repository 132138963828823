import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, Heart } from "lucide-react";
import { BlogContext } from "../context/BlogContext";

const ArticleDetail = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const {
    articles,
    handleUpdateViewCount,
    handleLikeToggle,
    handleAddComment,
    getComments,
    updateCommentCount,
  } = useContext(BlogContext);

  const [currentArticle, setCurrentArticle] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [viewed, setViewed] = useState(false);

  // Récupération des commentaires stabilisée
  const fetchComments = useCallback(
    async (articleId) => {
      try {
        const loadedComments = await getComments(articleId);
        setComments(loadedComments);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des commentaires:",
          error
        );
      }
    },
    [getComments]
  );

  // Charger l'article correspondant
  useEffect(() => {
    const foundArticle = articles.find(
      (article) => article.id === parseInt(documentId)
    );
    setCurrentArticle(foundArticle);
  }, [articles, documentId]);

  // Gestion des vues et chargement des commentaires
  useEffect(() => {
    if (currentArticle && !viewed) {
      const viewedArticles =
        JSON.parse(localStorage.getItem("viewedArticles")) || [];
      if (!viewedArticles.includes(currentArticle.id)) {
        handleUpdateViewCount(currentArticle.id);
        localStorage.setItem(
          "viewedArticles",
          JSON.stringify([...viewedArticles, currentArticle.id])
        );
      }
      setViewed(true);
    }
    if (currentArticle) {
      fetchComments(currentArticle.id);
    }
  }, [currentArticle, viewed, handleUpdateViewCount, fetchComments]);

  const handleLike = () => {
    handleLikeToggle(currentArticle.id);
  };

  const handleCommentSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const authorName = user?.username || "Utilisateur Anonyme";

    if (newComment.trim()) {
      const commentData = {
        auteur: authorName,
        contenu: newComment,
        date: new Date().toISOString(),
      };

      try {
        await handleAddComment(currentArticle.id, commentData);
        setNewComment("");
        const updatedComments = await getComments(currentArticle.id);
        setComments(updatedComments);
        localStorage.setItem(
          `commentsCount_${currentArticle.id}`,
          updatedComments.length
        );
        updateCommentCount(currentArticle.id, updatedComments.length);
      } catch (error) {
        console.error(
          "Erreur lors de l’ajout du commentaire:",
          error.response?.data || error.message
        );
      }
    }
  };

  if (!currentArticle) return <div>Aucun article trouvé.</div>;

  return (
    <div className="max-w-3xl mx-auto mt-20 px-4 pb-16">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-8 group"
      >
        <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
        <span>Retour aux articles</span>
      </button>

      <h1 className="text-3xl sm:text-4xl font-bold mb-4">
        {currentArticle.titre}
      </h1>
      <p className="text-sm sm:text-base text-gray-600 mb-8">
        Publié le {new Date(currentArticle.createdAt).toLocaleDateString()}
      </p>

      {currentArticle.image?.url && (
        <div className="mb-8">
          <img
            src={`${currentArticle.image.url}`}
            alt={currentArticle.titre}
            className="w-full h-64 sm:h-96 object-cover rounded-lg shadow-lg"
          />
        </div>
      )}

      <div
        className="content-blog prose prose-sm sm:prose-base max-w-none mb-12"
        dangerouslySetInnerHTML={{ __html: currentArticle.contenu }}
      ></div>

      <div className="border-t border-b border-gray-200 py-4 my-8">
        <div className="flex items-center gap-4 text-sm sm:text-base">
          <span>{currentArticle.vue_count} vues</span>
          <button onClick={handleLike} className="flex items-center gap-1">
            <Heart
              className={`${
                currentArticle.hasLiked
                  ? "text-red-500 fill-current"
                  : "text-gray-400"
              } transition-colors`}
            />
            <span>{currentArticle.likes_count}</span>
          </button>
          <span>
            {localStorage.getItem(`commentsCount_${currentArticle.id}`) ||
              comments.length}{" "}
            commentaires
          </span>
        </div>
      </div>

      <div>
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">
          Commentaires
        </h2>
        <div className="mb-8">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Ajouter un commentaire"
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            rows="4"
          />
          <button
            onClick={handleCommentSubmit}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg transition hover:bg-blue-600"
          >
            Commenter
          </button>
        </div>

        {comments.length > 0 ? (
          <div className="space-y-6">
            {comments.map((comment) => (
              <div key={comment.id} className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-900">
                    {comment.auteur || "Anonyme"}
                  </span>
                  <span className="text-gray-500 text-xs sm:text-sm">
                    {new Date(comment.date).toLocaleString()}
                  </span>
                </div>
                <p className="text-gray-700 text-sm sm:text-base">
                  {comment.attributes && comment.attributes.data
                    ? comment.attributes.data.contenu
                    : comment.contenu}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500 py-8">
            Aucun commentaire pour cet article.
          </p>
        )}
      </div>
    </div>
  );
};

export default ArticleDetail;
