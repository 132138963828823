import React, { useState, useEffect } from 'react';
import LoadingScreen from './components/LoadingScreen';
import { Routes, Route } from 'react-router-dom';
import { BlogProvider } from './context/BlogContext';
import { UserProvider } from './context/UserContext'; // Importez le UserProvider
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Inscription from './pages/Inscription';
import Connexion from './pages/Connexion';
import NosOffres from './pages/NosOffres';
import OffreSiteModele from './pages/OffreSiteModele';
import Tarifs from './pages/Tarifs';
import Portfolio from './pages/Portfolio';
import BlogPage from './pages/BlogPage';
import ArticleDetails from './pages/ArticleDetails';
import NousContacter from './pages/NousContacter';
import Error404 from './pages/Error404';
import CGU from './pages/CGU';
import CGV from './pages/CGV';
import MentionsLegales from './pages/MentionsLegales';
import PolitiquesDeConfidentialites from './pages/PolitiquesDeConfidentialites';
import PaiementSecurise from './pages/PaiementSecurise';
import ScrollToTop from './components/ScrollToTop';
import Shop from './pages/Shop';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import EmailConfirmationSuccess from './pages/EmailConfirmationSuccess';
import EmailVerificationPrompt from './pages/EmailVerificationPrompt';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Vérifie l'authentification au chargement
    const checkAuth = async () => {
      const token = localStorage.getItem('jwt');
      if (token) {
        // Attendre un peu pour s'assurer que tout est chargé
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <UserProvider> {/* Enveloppez l'application avec UserProvider */}
      <BlogProvider> {/* BlogProvider doit être enfant de UserProvider */}
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/login" element={<Connexion />} />
          <Route path="/nos-offres" element={<NosOffres />} />
          <Route path="/offre-site-modele" element={<OffreSiteModele />} />
          <Route path="/tarifs" element={<Tarifs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/article/:documentId" element={<ArticleDetails />} />
          <Route path="/nous-contacter" element={<NousContacter />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-verification-prompt" element={<EmailVerificationPrompt />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/email-confirmation-success" element={<EmailConfirmationSuccess />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/cgu" element={<CGU />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/paiement-securise" element={<PaiementSecurise />} />
          <Route
            path="/politique-confidentialite"
            element={<PolitiquesDeConfidentialites />}
          />
          <Route path="*" element={<Error404 />} />
          <Route path="/404" element={<Error404 />} />
        </Routes>
        <Footer />
      </BlogProvider>
    </UserProvider>
  );
}

export default App;
