import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {  UserPlus2, LogIn, LogOut, User } from 'lucide-react';
import logo from '../assets/logo.png';
import { logout } from '../api';
import Tooltip from '@mui/material/Tooltip';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('jwt');
  const menuRef = useRef(null);

  const getLinkClass = ({ isActive }) => {
    return isActive
      ? 'border-b-2 border-white hover:text-gray-300'
      : 'hover:text-gray-300';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('jwt');
    navigate('/');
  };

  // Ferme le menu lorsque l'utilisateur clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setAnimate(false), 1600); // Durée de 2 animations (0.8s * 2)
    return () => clearTimeout(timeout);
  }, []);

  const UserIcon = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const initials = user ? 
      `${user.firstName?.[0]}${user.lastName?.[0]}`.toUpperCase() : '';
    
    return (
      <Tooltip title={`${user?.firstName} ${user?.lastName} (${user?.email})`}>
        <div className="relative cursor-pointer">
          <div className="relative flex items-center justify-center">
            <User className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110" />
            {isAuthenticated && (
              <>
                <span className="absolute -bottom-4 text-xs font-medium text-white">
                  {initials}
                </span>
                <div className="absolute -top-0.5 -right-0.5">
                  <div className="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-500">
                    <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-black text-white py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img
              src={logo}
              alt="Logo du site"
              className={`h-12 md:h-20 ${animate ? 'start-animation' : ''}`}
            />
          </Link>
        </div>

        {/* Conteneur des icônes pour mobile/tablette */}
        <div className="lg:hidden flex items-center space-x-4">
          {!isAuthenticated ? (
            <div className="flex items-center gap-4">
              <Tooltip title="Inscription">
                <span className="cursor-pointer">
                  < UserPlus2 className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110" 
                  onClick={() => navigate('/inscription')} 
                  />
                </span>
              </Tooltip>
              <Tooltip title="Connexion">
                <span className="cursor-pointer">
                  <LogIn
                    className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                    onClick={handleLogin}
                  />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <UserIcon />
              <Tooltip title="Déconnexion">
                <span className="cursor-pointer">
                  <LogOut
                    className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                    onClick={handleLogout}
                  />
                </span>
              </Tooltip>
            </div>
          )}

          {/* Bouton Hamburger */}
          <button
            className="flex flex-col justify-center items-center space-y-1"
            onClick={toggleMenu}
          >
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
          </button>
        </div>

        {/* Menu de navigation */}
        <nav
          ref={menuRef}
          className={`${menuOpen ? 'block' : 'hidden'} lg:flex rounded-full rounded-tr-none items-center space-x-8 absolute lg:static top-16 md:top-24 right-4 md:right-8 w-1/2 lg:w-auto opacity-80 bg-[#232323] lg:bg-transparent`}
        >
          <ul className="flex flex-col items-center space-y-2 lg:space-y-0 lg:flex-row lg:space-x-6 p-4 lg:p-0">
            <li>
              <NavLink
                to="/"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/nos-offres"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Nos offres
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tarifs"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Tarifs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/shop"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/nous-contacter"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Contact
              </NavLink>
            </li>
          </ul>

          {/* Icônes pour le mode desktop */}
          <div className="hidden lg:flex items-center gap-4">
            {!isAuthenticated ? (
              <>
                <Tooltip title="Inscription">
                  <span className="cursor-pointer">
                    < UserPlus2 className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110" 
                    onClick={() => navigate('/inscription')} 
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Connexion">
                  <span className="cursor-pointer">
                    <LogIn
                      className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                      onClick={handleLogin}
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              <div className="flex items-center gap-4">
                <UserIcon />
                <Tooltip title="Déconnexion">
                  <span className="cursor-pointer">
                    <LogOut
                      className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                      onClick={handleLogout}
                    />
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
