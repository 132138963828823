import React from "react";
import { motion } from "framer-motion";
import { servicesData } from "../data/servicesData";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const NosOffres = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-14 sm:py-16">
      {/* Section Nos Offres */}
      <div className="container mt-10 sm:mt-14 md:mt-24 mx-auto px-4">
        <div className="text-center mb-10 sm:mb-16">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6">
            Nos Offres
          </h1>
          <p className="text-sm sm:text-base text-gray-600 max-w-md sm:max-w-2xl mx-auto">
            Chez VGom Creation, nous proposons une gamme complète de services
            web pour répondre à tous vos besoins digitaux.
          </p>
        </div>

        {/* Bouton retour */}
        <button
          onClick={() => navigate("/")}
          className="flex items-center gap-2 text-sm sm:text-base text-gray-600 hover:text-gray-900 transition-colors mb-6 sm:mb-8 group"
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour à l'accueil</span>
        </button>

        {/* Grille des services */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-12">
          {servicesData.map((service) => (
            <motion.div
              key={service.id}
              className={`relative flex flex-col items-center ${
                service.customDesign ? "p-6 rounded-lg shadow-lg" : ""
              }`}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.6,
                ease: "easeOut",
              }}
            >
              {/* Badge "À venir" */}
              {service.comingSoon && (
                <div className="absolute top-4 right-4 bg-yellow-500 text-white text-sm font-bold px-3 py-1 rounded-full">
                  À venir
                </div>
              )}

              {/* Image or Title Section */}
              {!service.customDesign ? (
                <>
                  <div className="flex items-center justify-center mb-4 sm:mb-6">
                    <img
                      src={service.image}
                      alt={service.title}
                      className="w-32 h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-44 lg:h-44 rounded-full object-cover"
                    />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-center">
                    {service.title}
                  </h3>
                  <ul className="space-y-2 text-sm sm:text-base text-gray-600">
                    {service.items.map((item, index) => (
                      <li key={index} className="flex items-start">
                        <span className="mr-2 text-eastern">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-center">
                    {service.title}
                  </h3>
                  <p className="text-sm sm:text-base mb-4 text-center">
                    {service.description}
                  </p>
                  <ul className="text-sm sm:text-base mb-6 text-left sm:text-center">
                    {service.details.map((detail, index) => (
                      <li key={index} className="mb-2">
                        {detail}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* Button Section */}
              {!service.comingSoon ? (
                <div className="mt-4 flex flex-col items-center">
                  <Link
                    to={service.link || "/tarifs"}
                    className="bg-purple-600 text-white px-4 py-2 rounded-lg transition hover:bg-purple-700"
                  >
                    {service.customDesign
                      ? "Découvrir l'offre"
                      : "En savoir plus"}
                  </Link>
                </div>
              ) : (
                <p className="mt-4 text-yellow-500 font-medium">
                  Disponible bientôt
                </p>
              )}
            </motion.div>
          ))}
        </div>
      </div>

      {/* Section informative sur les projets à vendre */}
      <div className="bg-gray-50 py-12 sm:py-16 mt-12 sm:mt-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">
            Des projets clé en main, prêts à l'achat
          </h2>
          <p className="text-sm sm:text-base text-gray-600 max-w-md sm:max-w-3xl mx-auto mb-6 sm:mb-8">
            En plus de nos services sur mesure, nous proposons des projets web
            clé en main, disponibles dans notre boutique. Ces projets incluent
            la personnalisation des textes et des images, qui doivent être
            fournis par le client.
          </p>
          <Link
            to="/shop"
            className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg hover:bg-eastern transition"
          >
            Découvrir nos projets à vendre
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NosOffres;
