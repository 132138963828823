import { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

import { FacebookIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { IoShareSocial, IoClose } from 'react-icons/io5';

const XIcon = () => (
  <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center overflow-hidden transform transition-transform duration-300 hover:scale-110">
    <img
      src="https://img.icons8.com/forma-regular/72/twitterx.png"
      alt="twitterx"
      style={{
        width: '70%',
        height: '70%',
        objectFit: 'cover',
        filter: 'invert(1)',
      }}
    />
  </div>
);

const BoutonsPartage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const url = 'https://www.vgomcreation.fr';
  const title = 'Découvrez VGom Creation - Création de sites web sur mesure !';
  const hashtags = ['webdesign', 'VGomCreation', 'sitesweb'];

  return (
    <div className="fixed right-4 top-96 flex flex-col items-center gap-2 z-50">
      {/* Icônes de réseaux sociaux */}
      <div
        className={`absolute flex flex-col items-center gap-2 bottom-full transition-all duration-300 ${
          isOpen
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-10 pointer-events-none'
        }`}
      >
        <FacebookShareButton url={url} quote={title} hashtags={hashtags}>
          <FacebookIcon
            size={50}
            round
            className="transform transition-transform duration-300 hover:scale-110"
          />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title} hashtags={hashtags}>
          <XIcon />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={title}>
          <LinkedinIcon
            size={50}
            round
            className="transform transition-transform duration-300 hover:scale-110"
          />
        </LinkedinShareButton>
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon
            size={50}
            round
            className="transform transition-transform duration-300 hover:scale-110"
          />
        </WhatsappShareButton>
      </div>

      {/* Bouton principal */}
      <div className="relative group">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 mt-2 shadow-lg transform transition-transform duration-300 hover:scale-110"
          aria-label={isOpen ? 'Fermer le menu de partage' : 'Ouvrir le menu de partage'}
        >
          {isOpen ? <IoClose size={24} /> : <IoShareSocial size={24} />}
        </button>

        {/* Tooltip */}
        <div className="absolute mr-1 right-full top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
          {isOpen ? 'Fermer' : 'Partager'}
        </div>
      </div>
    </div>
  );
};

export default BoutonsPartage;
