import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api';
import { FaEye, FaEyeSlash, FaCheckCircle } from 'react-icons/fa';
import { MdLockOutline, MdCheckCircleOutline } from 'react-icons/md';
import { UserContext } from '../context/UserContext';
import { RefreshCw } from 'lucide-react';

function Connexion() {
  const [formData, setFormData] = useState({
    identifier: '', // Pour email ou username
    password: '',
  });
  const [error, setError] = useState(null); // État pour les erreurs de connexion
  const [showPassword, setShowPassword] = useState(false); // Afficher ou masquer le mot de passe
  const [success, setSuccess] = useState(false); // Connexion réussie
  const [isLoading, setIsLoading] = useState(false); // Indique si une requête est en cours
  const navigate = useNavigate();
  const { loginUser, user, isAuthChecking } = useContext(UserContext); // Context utilisateur
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Vérification initiale de l'authentification
  useEffect(() => {
    if (!isAuthChecking && user) {
      setIsRedirecting(true);
      navigate('/', { replace: true });
    }
  }, [user, isAuthChecking, navigate]);

  // Si l'authentification est en cours de vérification ou redirection, afficher le loading
  if (isAuthChecking || isRedirecting) {
    return (
      <div className="fixed inset-0 bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <RefreshCw className="w-12 h-12 text-white animate-spin" />
          <p className="text-white text-lg font-medium">Chargement...</p>
        </div>
      </div>
    );
  }

  // Si l'utilisateur est déjà connecté, ne rien afficher
  if (user) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
  
    try {
      const data = await login(formData);
      setSuccess(true);
      setIsLoading(false);
      
      // Petit délai avant de mettre à jour le contexte et rediriger
      setTimeout(() => {
        loginUser(data.user, data.jwt);
        setIsRedirecting(true);
        navigate("/");
      }, 1000); // Laisse le temps de voir le message de succès
  
    } catch (err) {
      setError(err.response?.data?.error?.message || "Identifiants incorrects");
      setIsLoading(false);
    }
  };

  // Afficher ou masquer le mot de passe
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
      <div className="w-full max-w-md sm:max-w-lg lg:max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-lg relative">
        {/* Icône Connexion ou Succès */}
        <div className="absolute top-4 right-4">
          {success ? (
            <MdCheckCircleOutline size={40} className="text-green-500" />
          ) : (
            <MdLockOutline size={40} className="text-purple-500" />
          )}
        </div>

        {/* Titre et Sous-titre */}
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 text-center mb-2 sm:mb-4">
          Connexion
        </h2>
        <p className="text-sm sm:text-base text-gray-500 text-center mb-4 sm:mb-6">
          Connectez-vous avec vos informations pour accéder à votre compte.
        </p>

        {/* Message d'erreur ou de succès */}
        {error && (
          <p className="text-sm sm:text-base text-red-500 text-center mb-4">
            {error}
          </p>
        )}
        {success && (
          <div className="flex items-center justify-center text-green-500 mb-4 text-sm sm:text-base">
            <FaCheckCircle className="mr-2" />
            <span>Connexion réussie ! Redirection...</span>
          </div>
        )}

        {/* Formulaire */}
        <form onSubmit={handleLogin} className="space-y-4 sm:space-y-6">
          {/* Champ Identifiant */}
          <div>
            <label
              htmlFor="identifier"
              className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
            >
              Nom d'utilisateur ou e-mail
            </label>
            <input
              type="text"
              id="identifier"
              name="identifier"
              value={formData.identifier}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* Champ Mot de passe */}
          <div>
            <label
              htmlFor="password"
              className="block text-sm sm:text-base font-medium text-gray-700 mb-1"
            >
              Mot de passe
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg pr-12 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                aria-label={
                  showPassword
                    ? 'Masquer le mot de passe'
                    : 'Afficher le mot de passe'
                }
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          {/* Lien Mot de passe oublié */}
          <div className="text-right">
            <button
              type="button"
              className="text-sm text-blue-600 hover:underline"
              onClick={() => navigate('/forgot-password')}
            >
              Mot de passe oublié ?
            </button>
          </div>

          {/* Bouton Connexion avec état de chargement */}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-br from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg disabled:opacity-50"
          >
            {isLoading ? 'Connexion en cours...' : 'Se connecter'}
          </button>
        </form>

        {/* Lien vers Inscription */}
        <div className="text-center mt-6">
          <p className="text-sm sm:text-base text-gray-600">
            Pas encore de compte ?{' '}
            <button
              className="text-purple-600 hover:underline"
              onClick={() => navigate('/inscription')}
            >
              Inscrivez-vous
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Connexion;
