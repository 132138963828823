import React from "react";

const Accordion = ({ title, children, isOpen, onToggle }) => {
  return (
    <div
      className={`border rounded-lg shadow w-full overflow-hidden transition-all duration-300 ${
        isOpen ? "bg-gray-50 border-gray-300" : "bg-gray-100 border-gray-200"
      }`}
    >
      {/* Bouton du titre de l'accordéon */}
      <button
        onClick={onToggle}
        aria-expanded={isOpen}
        aria-controls="accordion-content"
        className={`w-full text-left p-4 font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          isOpen
            ? "bg-gray-200 hover:bg-gray-300"
            : "bg-gray-100 hover:bg-gray-200"
        }`}
      >
        {title}
      </button>

      {/* Contenu de l'accordéon */}
      <div
        id="accordion-content"
        className={`transition-all duration-300 ${
          isOpen ? "max-h-screen p-4" : "max-h-0 p-0"
        }`}
        style={{
          overflow: isOpen ? "visible" : "hidden",
        }}
      >
        <div
          className={`transition-opacity duration-300 ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
