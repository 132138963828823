import React from 'react';
import { RefreshCw } from "lucide-react";

function LoadingScreen() {
  return (
    <div className="fixed inset-0 bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] flex items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <RefreshCw className="w-12 h-12 text-white animate-spin" />
        <p className="text-white text-lg font-medium">Chargement...</p>
      </div>
    </div>
  );
}

export default LoadingScreen;