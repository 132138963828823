import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../api';
import { Eye, EyeOff, CheckCircle } from "lucide-react";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const code = searchParams.get('code');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Validations
      if (!code) {
        throw new Error("Code de réinitialisation manquant");
      }

      if (password !== confirmPassword) {
        throw new Error("Les mots de passe ne correspondent pas");
      }

      if (password.length < 6) {
        throw new Error("Le mot de passe doit contenir au moins 6 caractères");
      }

      const data = await resetPassword({
        code: code,
        password: password,
        passwordConfirmation: confirmPassword,
      });

      console.log('Réponse de réinitialisation:', data);
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 3000);

    } catch (err) {
      console.log('Erreur complète:', err);
      
      // Gestion des différents types d'erreurs
      if (err.response?.data?.error?.message) {
        setError(err.response.data.error.message);
      } else if (err.message) {
        setError(err.message);
      } else {
        setError("Une erreur est survenue lors de la réinitialisation du mot de passe");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#3b82f6] to-[#8b5cf6] p-4 md:py-36">
      <div className="w-full max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Réinitialisation du mot de passe
        </h2>

        {success ? (
          <div className="text-green-600 text-center flex flex-col items-center gap-4 p-4">
            <CheckCircle className="w-12 h-12" />
            <p className="text-lg">Mot de passe modifié avec succès !</p>
            <p className="text-gray-600">Redirection vers la page de connexion...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="text-red-600 text-center p-3 bg-red-50 rounded-md border border-red-200">
                {error}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nouveau mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 pr-10"
                  required
                  minLength={6}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Confirmer le mot de passe
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 pr-10"
                  required
                  minLength={6}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showConfirmPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg"
            >
              Réinitialiser le mot de passe
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;