import React, { useContext } from 'react';
import { Eye, Heart, ArrowLeft, MessageSquare } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { BlogContext } from '../context/BlogContext';

const BlogPage = () => {
  // Utiliser les articles et les fonctions du contexte
  const { articles } = useContext(BlogContext);
  const navigate = useNavigate();

  // Afficher un message si aucun article n'est disponible
  if (!articles.length)
    return <p>Aucun article n'est disponible pour le moment.</p>;

  return (
    <div className="max-w-4xl p-4 md:mx-auto mb-10 sm:mb-20 mt-10 md:mt-20">
      {/* Section Titre */}
      <div className="text-center py-10 sm:py-16">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-2">
          Notre Blog
        </h1>
        <p className="text-sm sm:text-base text-gray-500 max-w-md sm:max-w-xl mx-auto">
          Explorez nos articles pour découvrir des conseils, des idées et des
          actualités sur des sujets qui nous passionnent. Que vous soyez ici
          pour apprendre, vous inspirer ou rester informé, notre blog a quelque
          chose pour vous !
        </p>
      </div>

      {/* Bouton retour */}
      <button
        onClick={() => navigate('/')}
        className="flex items-center gap-2 text-sm sm:text-base text-gray-600 hover:text-gray-900 transition-colors mb-6 sm:mb-8 group"
      >
        <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transform group-hover:-translate-x-1 transition-transform" />
        <span>Retour à l'accueil</span>
      </button>

      {/* Boucle à travers les articles et affiche chaque article */}
      {articles.map((article) => (
        <div
          key={article.id}
          className="cursor-pointer group mb-8"
          onClick={() => navigate(`/article/${article.id}`)} // Redirection vers la page de détail
        >
          <div className="flex flex-col md:flex-row gap-8">
            {/* Affichage de l'image de l'article */}
            {article.image?.url ? (
              <div className="w-full md:w-1/3 h-48 md:h-64 overflow-hidden">
                <img
                  src={`${article.image.url}`}
                  alt={article.titre}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
              </div>
            ) : (
              <div className="w-full md:w-1/3 h-48 md:h-64 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500 text-xs sm:text-sm md:text-base">
                  Aucune image disponible
                </span>
              </div>
            )}

            {/* Affichage du titre, description, auteur, et statistiques */}
            <div className="w-full md:w-2/3">
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  <div className="w-8 h-8 bg-violetta rounded-full flex items-center justify-center text-white font-bold mr-2">
                    {article.auteur?.charAt(0) || 'A'}
                  </div>
                  <div>
                    <p className="text-xs sm:text-sm font-medium">
                      {article.auteur || 'Auteur inconnu'}
                    </p>
                    <p className="text-xs text-gray-500">
                      {article.temps_lecture} min de lecture
                    </p>
                  </div>
                </div>
              </div>

              {/* Affichage de la date de publication */}
              <p className="text-xs sm:text-sm text-gray-500 mb-2">
                Publié le {new Date(article.createdAt).toLocaleDateString()}
              </p>
              <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-3 group-hover:text-eastern transition-colors">
                {article.titre}
              </h2>

              <p className="text-sm sm:text-base text-gray-600 mb-4 line-clamp-3">
                {article.description}
              </p>

              {/* Affichage des compteurs de vues, likes et commentaires */}
              <div className="flex items-center justify-between">
                <div className="flex items-center text-xs sm:text-sm text-gray-500 space-x-4">
                  <div className="flex items-center">
                    <Eye size={14} className="mr-1" />
                    <span>{article.vue_count || 0}</span>
                  </div>
                  <div className="flex items-center">
                    <Heart
                      size={14}
                      className={`mr-1 ${
                        article.hasLiked
                          ? 'text-red-500 fill-current'
                          : 'text-gray-400'
                      }`}
                    />
                    <span>{article.likes_count || 0}</span>
                  </div>
                  <div className="flex items-center">
                    <MessageSquare size={14} className="mr-1" />
                    <span>
                      {article.commentaire_count || 0} commentaire
                      {article.commentaire_count !== 1 ? 's' : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 mt-8"></div>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
