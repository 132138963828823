import React from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import {
  FaCcVisa,
  FaCcMastercard,
  FaPaypal,
  FaLock,
  FaCheckCircle,
} from 'react-icons/fa';

const Tarifs = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-14 sm:py-20">
      {/* Section Tarifs */}
      <div className="container mt-10 md:mt-20 mx-auto px-4">
        <div className="text-center mb-12 sm:mb-16">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
            Nos Tarifs
          </h1>
          <p className="text-gray-600 max-w-lg sm:max-w-2xl mx-auto text-sm sm:text-base mb-6">
            Découvrez nos offres tarifaires adaptées à vos besoins et budgets.
            Chez VGom Creation, nous proposons des solutions claires,
            abordables, et flexibles pour la création de sites web
            professionnels.
          </p>
        </div>

        {/* Bouton retour */}
        <button
          onClick={() => navigate('/nos-offres')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-6 sm:mb-8 group"
        >
          <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour aux offres</span>
        </button>

        {/* Tableau des tarifs */}
        <div className="bg-gray-50 py-8 sm:py-10 rounded-lg shadow-md">
          <div className="container mx-auto px-4 max-w-4xl">
            <div className="overflow-x-auto">
              <table className="table-auto w-full text-left border-collapse border border-gray-200">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-3 sm:px-4 py-2 border border-gray-200 font-semibold text-sm sm:text-base">
                      Forfait
                    </th>
                    <th className="px-3 sm:px-4 py-2 border border-gray-200 font-semibold text-sm sm:text-base">
                      Tarif (€) TTC
                    </th>
                    <th className="px-3 sm:px-4 py-2 border border-gray-200 font-semibold text-sm sm:text-base">
                      Description
                    </th>
                    <th className="px-3 sm:px-4 py-2 border border-gray-200 font-semibold text-sm sm:text-base">
                      Délai moyen
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      title: 'Essentiel',
                      price: 499.99,
                      description:
                        'Site vitrine (1à 3 pages), responsive, formulaire de contact.',
                      delay: '10-15 jours ouvrés',
                    },
                    {
                      title: 'Professionnel',
                      price: 999.99,
                      description:
                        'Multi-pages (4 à 7 pages), blog ou galerie, SEO de base.',
                      delay: '25-35 jours ouvrés',
                    },
                    {
                      title: 'Sur mesure',
                      price: 1499.99,
                      description:
                        'Boutique en ligne, intégrations avancées, SEO optimisé.',
                      delay: '35-45 jours ouvrés',
                    },
                  ].map((item, index) => (
                    <motion.tr
                      key={index}
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.4, delay: index * 0.2 }}
                    >
                      <td className="px-3 sm:px-4 py-2 border border-gray-200 text-sm sm:text-base">
                        {item.title}
                      </td>
                      <td className="px-3 sm:px-4 py-2 border border-gray-200 text-sm sm:text-base">
                        {`À partir de ${item.price.toFixed(2)} €`}
                      </td>
                      <td className="px-3 sm:px-4 py-2 border border-gray-200 text-sm sm:text-base">
                        {item.description}
                      </td>
                      <td className="px-3 sm:px-4 py-2 border border-gray-200 text-sm sm:text-base">
                        {item.delay}
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>

              {/* Modalités de paiement */}
              <div className="bg-white mt-8 py-4 px-6 rounded-lg shadow-md border border-gray-200">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
                  Modalités de paiement
                </h3>
                <p className="text-sm sm:text-base text-gray-700 mb-3">
                  Pour tous les forfaits, le paiement s'effectue en trois étapes
                  pour garantir un suivi optimal et une répartition équilibrée :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 mb-4">
                  <li>30% à la commande.</li>
                  <li>50% en cours de projet.</li>
                  <li>20% avant la livraison finale.</li>
                </ul>
                <p className="text-sm sm:text-base text-gray-700">
                  Ces modalités assurent un contrôle régulier du projet tout en
                  répartissant les paiements selon les étapes clés de la
                  prestation.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Options supplémentaires */}
        <div className="mt-12 sm:mt-16 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">
            Options supplémentaires
          </h2>
          <p className="text-gray-600 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-6 sm:mb-8">
            Personnalisez davantage votre site avec nos options supplémentaires.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {[
              [
                'Page supplémentaire : 100 €',
                'Blog avec gestion des posts : 500 €',
                'Intégration de Stripe ou PayPal : 500 €',
                'Maintenance mensuelle : 100 €/mois',
              ],
              [
                'Audit technique complet : 400 - 800 €',
                'Optimisation de la vitesse : 300 - 600 €',
                'Debugging (simple) : 50 - 100 €/heure',
                'SEO on-page (par page) : 100 - 200 €',
              ],
            ].map((list, idx) => (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                className="bg-blue-50 p-6 rounded-lg shadow-lg"
              >
                <ul className="space-y-4 text-left">
                  {list.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 text-sm sm:text-lg text-gray-700"
                    >
                      <FaCheckCircle className="text-green-500 w-5 h-5" />
                      {item}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Section Modes de paiement */}
        <div className="mt-12 sm:mt-16 flex justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
            className="text-left w-full max-w-4xl"
          >
            <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center">
              Modes de paiement disponibles
            </h2>
            <p className="text-gray-600 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-8 text-center">
              Nous acceptons plusieurs modes de paiement pour vous faciliter la
              gestion de vos transactions. Tous nos paiements sont sécurisés
              grâce à <span className="font-bold">Stripe</span>.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[
                {
                  icon: <FaCcVisa className="text-blue-500 w-6 h-6" />,
                  text: 'Carte Visa',
                },
                {
                  icon: <FaCcMastercard className="text-orange-500 w-6 h-6" />,
                  text: 'MasterCard',
                },
                {
                  icon: <FaPaypal className="text-blue-600 w-6 h-6" />,
                  text: 'PayPal',
                },
                {
                  icon: <FaLock className="text-gray-600 w-6 h-6" />,
                  text: 'Virement bancaire sécurisé',
                },
                {
                  icon: <FaLock className="text-gray-600 w-6 h-6" />,
                  text: 'Paiement en plusieurs fois',
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 text-sm sm:text-lg text-gray-700"
                >
                  {item.icon}
                  {item.text}
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Appel à l'action */}
      <div className="bg-gray-50 py-12 sm:py-16 mt-12 sm:mt-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">
            Intéressé par nos offres ?
          </h2>
          <p className="text-gray-600 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-6 sm:mb-8">
            Contactez-nous pour un devis personnalisé ou pour toute question sur
            nos services et tarifs. Nous sommes là pour vous accompagner.
          </p>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <Link
              to="/nous-contacter"
              className="bg-black text-white px-6 py-3 rounded-lg text-sm sm:text-lg hover:bg-eastern transition"
            >
              Nous contacter
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Tarifs;
