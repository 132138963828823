import React, { useState, useEffect } from 'react';
import { getAllProjects } from '../api';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAllProjects(); // Appel de la fonction getAllProjects depuis api.js
        setProjects(data.data); // Accès aux données renvoyées par Strapi
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
      }
    };

    fetchProjects();
  }, []);

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  return (
    <div className="bg-gray-100 text-black py-6 lg:py-16">
      <div className="container mx-auto px-4">
        {/* Titre principal */}
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 lg:mb-2">
          Nos projets
        </h2>

        {/* Lien vers le portfolio */}
        <Link
          to="/portfolio"
          className="flex items-center justify-end mb-4 sm:mb-6 text-sm sm:text-base text-gray-600 hover:text-eastern transition-colors group"
        >
          Voir tous les projets
          <ArrowRight
            className="ml-2 group-hover:translate-x-2 transition-transform"
            size={20}
          />
        </Link>

        {/* Sous-titre */}
        <p className="text-sm sm:text-base text-gray-700 mb-6 sm:mb-8">
          Réalisation et Innovation
        </p>

        {/* Description */}
        <p className="text-sm sm:text-base md:text-lg mb-8 sm:mb-12 md:mb-16 max-w-2xl text-gray-600">
          Découvrez nos projets qui allient créativité, fonctionnalités avancées
          et design moderne.
        </p>

        {/* Liste des projets */}
        <div className="space-y-4 md:space-y-8">
          {projects.slice(0, 3).map((project) => (
            <Link to={`/portfolio`} key={project.id} className="block">
              <div className="group border-b p-7 rounded-2xl border-gray-200 py-8 bg-gray-200 hover:bg-gray-100 transition-all cursor-pointer">
                <div className="flex justify-between items-center">
                  {' '}
                  {/* Utilisation de `items-start` */}
                  <h3 className="text-lg md:text-4xl font-light">
                    {project?.titre || 'Titre non disponible'}
                  </h3>
                  <p className="text-gray-600 text-xs md:text-sm text-right">
                    {project?.description || 'Description non disponible'}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
