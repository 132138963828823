import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="relative max-w-full overflow-hidden">
      {/* Slider */}
      <Slider {...settings} className="mx-auto">
        <div>
          <img
            src="/images/1.webp"
            alt="Bureau de Vincent"
            className="w-full h-[350px] md:h-[550px] object-cover"
          />
        </div>
        <div>
          <img
            src="/images/2.webp"
            alt="Poste de travail"
            className="w-full h-[350px] md:h-[550px] object-cover"
          />
        </div>
      </Slider>

      {/* Overlay and Content */}
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-4 mt-16">
        {/* Title */}
        <h1
          className="text-3xl md:text-5xl font-bold mb-1 text-center bg-white text-bootstrap bg-clip-text"
          style={{
            WebkitTextStroke: '1px var(--tw-color-bootstrap)', // Contour blanc
            textShadow: '2px 2px 6px rgba(0, 0, 0, 0.8)', // Ombre noire
          }}
        >
          Création de Sites Web
        </h1>

        {/* Description */}
        <p className="text-base md:text-xl max-w-xl text-center mb-1 sm:mb-4 drop-shadow-lg animate-fadeIn">
          Pour les petites entreprises & associations.
        </p>

        {/* Responsive Divider */}
        <div className="w-12 md:w-20 h-[2px] bg-gray-300 my-4"></div>

        {/* Button Description */}
        <p className="text-sm md:text-base text-center mb-6 max-w-lg">
          Obtenez votre <strong>devis gratuit</strong> sous 24h en complétant le formulaire !
        </p>

        {/* Button */}
        <a
          href="https://forms.gle/Wqq9dcyKvvYXVtKM6"
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-2 md:px-6 md:py-3 bg-blue-600 hover:bg-blue-700 text-white text-base md:text-lg font-semibold rounded-lg shadow-md hover:scale-105 transform transition duration-300 focus:ring focus:ring-blue-300"
        >
          Formulaire
        </a>
      </div>
    </div>
  );
};

export default Banner;
