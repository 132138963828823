export const servicesData = [
  {
    id: 1,
    title: "Création de sites vitrine moderne",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807706/1_hmhxoj.webp",
    items: [
      "Design attractif et professionnel",
      "Adaptabilité mobile, tablette et ordinateur",
      "Personnalisation simple et rapide",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: 2,
    title: "Optimisation de petits sites web",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/2_cmqhjz.webp",
    items: [
      "Amélioration des performances globales",
      "Optimisation des images pour plus de rapidité",
      "Simplification de la structure pour le SEO",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: 3,
    title: "SEO (Référencement naturel)",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/3_psnqe8.webp",
    items: [
      "Audit SEO facile à comprendre",
      "Optimisation du contenu pour le SEO",
      "Recherche de mots-clés basiques",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: 4,
    title: "Accessibilité web et conformité WCAG",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/4_mkiknc.webp",
    items: [
      "Analyse d'accessibilité de base",
      "Application des normes essentielles WCAG 2.1",
      "Amélioration de la navigation pour tous",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: 5,
    title: "Maintenance et support",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/5_jf7ogo.webp",
    items: [
      "Résolution rapide des bugs",
      "Mises à jour régulières pour sécurité",
      "Assistance technique simple",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: 6,
    title: "Accompagnement & conseils personnalisés",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/6_vybz99.webp",
    items: [
      "Aide pour choisir un design adapté",
      "Recommandation d'hébergeurs abordables",
      "Conseils pour acheter un nom de domaine",
    ],
    comingSoon: false, // Disponible immédiatement
  },
  {
    id: "special",
    title: "Abonnement à 9,99€/mois",
    customDesign: true,
    comingSoon: true, // Indique que l'offre est "À venir"
    description:
      "Créez un site web vitrine professionnel (1 page) pour seulement 9,99€/mois. Ce forfait inclut :",
    details: [
      "✔ Maintenance et mises à jour régulières",
      "✔ Personnalisation des textes et images",
      "✔ Domaine personnalisé (20€/an chez un fournisseur)",
      "✔ Possibilité d'ajouter des fonctionnalités avec paiement ponctuel",
    ],
    link: "/offre-site-modele",
    background: "bg-gradient-to-br from-blue-600 to-purple-600 text-white",
  },
];
